import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

const serverConfig = require("../config/server.js");

const LogoName = (props) => {

  const [logoBase64Text, setLogoBase64Text] = useState('');

  useEffect(() => {
  
    console.log(window.location.hostname);

    axios.post(serverConfig.api_base_url + "get_logo_image",{domainName:window.location.host})
    .then(response=>{
      console.log('get_logo_image response', response);
      if(response.data.status === 200) {
        setLogoBase64Text(response.data.logoBase64);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
	},[]);

  return (
    <Link to="/" className='logo' target={props.target}><img src={logoBase64Text} alt="Logo" /></Link>
  );
}

export default LogoName;
