import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Form, Table, Modal} from 'react-bootstrap';
import axios from "axios";
import ReactPlayer from 'react-player';
import Footer from '../components/Footer.jsx';
import LogoName from '../components/LogoName.jsx';

const serverConfig = require("../config/server.js");

const Estimate = () => {

  const [calculated, setCalculated] = useState(false);

  const [verifyCompanyInfoText, setVerifyCompanyInfoText] = useState(null);
  const [inputs, setInputs] = useState({});
  const [inputsGR, setInputsGR] = useState({});
  const [inputsSignup, setInputsSignup] = useState({});

  const [btnsFormCompanyInfoDisabled, setBtnsFormCompanyInfoDisabled] = useState(true);
  const [btnClearFormGRDisabled, setBtnClearFormGRDisabled] = useState(true);
  const [btnSubmitFormGRDisabled, setBtnSubmitFormGRDisabled] = useState(true);

  const [sliderMinVal, setSliderMinVal] = useState(null);
  const [sliderMaxVal, setSliderMaxVal] = useState(null);
  const [slider1Val, setSlider1Val] = useState("");
  const [slider2Val, setSlider2Val] = useState("");
  const [slider1Per, setSlider1Per] = useState(null);
  const [slider2Per, setSlider2Per] = useState(null);
  const [vkfeQ12021, setVkfeQ12021] = useState(null);
  const [vkfeQ22021, setVkfeQ22021] = useState(null);
  const [vkfeQ32021, setVkfeQ32021] = useState(null);
  const [vkfe2020, setVkfe2020] = useState(null);

  const [companyInfoError, setCompanyInfoError] = useState(null);
  const [companyInfoResetBtnDisabled, setCompanyInfoResetBtnDisabled] = useState(true);

  useEffect(() => {
    //handleRangeSlider();
    resetCompanyInfo();

    //set_log
    let formdata = {
      category: "vkfe",
      log_message: {
        action: 'visited',
        reference: window.location.search.split('?')[1].split("&")[0]
      }
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_log", formdata)
    .then(response=>{
      console.log('set_log response', response);
    })
    .catch(err=>console.log("error is",err));
  }, []);

  /* const handleRangeSlider = () => {
    let minVal = parseInt(document.getElementById('slider-1').min);
    let maxVal = parseInt(document.getElementById('slider-1').max);
    let sl1Val = parseInt(document.getElementById('slider-1').value);
    let sl2Val = parseInt(document.getElementById('slider-2').value);
    let per1 = ((sl1Val-minVal) / (maxVal-minVal)) * 100;
    let per2 = ((sl2Val-minVal) / (maxVal-minVal)) * 100;

    console.log(minVal);
    console.log(maxVal);
    console.log(sl1Val);
    console.log(sl2Val);
    console.log(per1);
    console.log(per2);

    setSliderMinVal(minVal);
    setSliderMaxVal(maxVal);
    setSlider1Val(sl1Val);
    setSlider2Val(sl2Val);
    setSlider1Per(per1);
    setSlider2Per(per2);

    document.getElementById('slider-track').style.background = `linear-gradient(to right, var(--color2) ${per1}%, var(--color1) ${per1}%, var(--color1) ${per2}%, var(--color2) ${per2}%)`;
  } */

  const getInitialMinMax = () => {
    console.log(inputs);

    axios.post(serverConfig.api_base_url + "get_vkfe_min_max", {loan_number:inputs.ppp_loan_number})
    .then(response=>{
      console.log('get_vkfe_min_max response', response);
      if(response.data.status === 200){
        setCalculated(true);
        setCompanyInfoError(null);
        setVerifyCompanyInfoText(null);
        setCompanyInfoResetBtnDisabled(false);
        let data = JSON.parse(response.data.items);
        console.log(data);

        let minVal = 0;
        let maxVal = data.maxval + 200000;
        let sl1Val = data.minval;
        let sl2Val = data.maxval;
        let per1 = ((sl1Val-minVal) / (maxVal-minVal)) * 100;
        let per2 = ((sl2Val-minVal) / (maxVal-minVal)) * 100;

        console.log(minVal);
        console.log(maxVal);
        console.log(sl1Val);
        console.log(sl2Val);
        console.log(per1);
        console.log(per2);

        setSliderMinVal(minVal);
        setSliderMaxVal(maxVal);
        setSlider1Val(Math.round(sl1Val));
        setSlider2Val(Math.round(sl2Val));
        setSlider1Per(per1);
        setSlider2Per(per2);
        setVkfeQ12021(data.vkfeQ12021);
        setVkfeQ22021(data.vkfeQ22021);
        setVkfeQ32021(data.vkfeQ32021);
        setVkfe2020(data.vkfe2020);

        setTimeout(() => {
          document.getElementById("range1").style.left = `calc(${per1}% - ${document.getElementById('range1').offsetWidth}px)`;
          document.getElementById('slider-track').style.background = `linear-gradient(to right, var(--color2) ${per1}%, var(--color1) ${per1}%, var(--color1) ${per2}%, var(--color2) ${per2}%)`;
        }, 100);
      } else {
        setCompanyInfoError(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const handleCalculate = (event) => {
    event.preventDefault();
    getInitialMinMax();

    //set_log
    let formdata = {
      category: "vkfe",
      log_message: {
        action: 'submitted',
        reference: window.location.search.split('?')[1].split("&")[0],
        input: {
          company_name: inputs.company_name,
          loan_no: inputs.ppp_loan_number
        }
      }
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_log", formdata)
    .then(response=>{
      console.log('set_log response', response);
    })
    .catch(err=>console.log("error is",err));
  }

  const clearCompanyInfo = (event) => {
    setVerifyCompanyInfoText(null);
    setCompanyInfoResetBtnDisabled(false);
    setInputs({});
    let elements = document.querySelectorAll('#form-company-info input[type="text"]');
    for(let i=0; i<elements.length; i++){
      elements[i].value='';
    }
    document.querySelectorAll('#form-company-info input[type="number"]').value='';
    setBtnsFormCompanyInfoDisabled(true);

    setCalculated(false);
    setCompanyInfoError(null);

    handleClearGRForm();
  }

  const [videoUrl, setVideoUrl] = useState(null);
  const [unauthorized, setUnauthorized] = useState(true);
  const [loading, setLoading] = useState(true);

  const resetCompanyInfo = () => {
    axios.post(serverConfig.api_base_url + "get_vkfe_detail", {ref_token:window.location.search.split('?')[1].split("&")[0]})
    .then(response=>{
      console.log('get_vkfe_detail response', response);
      setLoading(false);
      if(response.data.status === 200){
        setUnauthorized(false);
        if(response.data.items !== null){
          let items = JSON.parse(response.data.items);
          console.log(items);
          setVerifyCompanyInfoText('Our records indicate the following information of yours, please verify and submit.');
          setCompanyInfoResetBtnDisabled(true);
          setVideoUrl(items.video_url);
          setInputs({
            company_name: items.company_name,
            ppp_loan_number: items.loan_no,
          });
          setBtnsFormCompanyInfoDisabled(false);

          setCalculated(false);
          setCompanyInfoError(null);

          handleClearGRForm();
          setInputsSignup({});
          let elements = document.querySelectorAll('#form-signup input[type="text"]');
          for(let i=0; i<elements.length; i++){
            elements[i].value='';
          }
          document.querySelectorAll('#form-signup input[type="email"]').value='';
        }
      } else {
        setUnauthorized(true);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
    setInputs((stateInputs) => {
      //console.log(stateInputs);
      let totalInputsLength = document.querySelectorAll('#form-company-info input[type="text"]').length + document.querySelectorAll('#form-company-info input[type="number"]').length;
      //console.log(Object.values(stateInputs).every(x => x !== ''), totalInputsLength, Object.keys(stateInputs).length);
      if((totalInputsLength === Object.keys(stateInputs).length) && (Object.values(stateInputs).every(x => x !== ''))){ //check if all the company input fields are filled
        setBtnsFormCompanyInfoDisabled(false);
      } else {
        setBtnsFormCompanyInfoDisabled(true);
      }
      return stateInputs;
    });
  }

  const handleNumber = (event) => {
    if(["e", "E", "+", "-"].includes(event.key)){
      event.preventDefault();
    }
  }

  /* Signup form - start */
  const [signupEmail, setSignupEmail] = useState(null);
  const [showEnterOTP, setShowEnterOTP] = useState(false);
  const handleCloseEnterOTP = () => {
    setDomainError(null);
    setInputsSignup({});
    let elements = document.querySelectorAll('#form-signup input[type="text"]');
    for(let i=0; i<elements.length; i++){
      elements[i].value='';
    }
    document.querySelectorAll('#form-signup input[type="email"]').value='';
    setOtp("");
    setOtpError(null);
    setVerifyDisabled(true);
    setResendDisabled(true);
    setOtpGenerated(false);
    //console.log(otpGenerated);
    if(otpGenerated){
      document.getElementById('otp').value = '';
      document.getElementById('timer').innerHTML = '02:00';
      document.getElementById('resend-otp').style.display = "block";
      document.getElementById('generate-otp').style.display = "none";
    }
    setShowEnterOTP(false);
  }
  const handleShowEnterOTP = () => setShowEnterOTP(true);

  const [showThankyouSignup, setShowThankyouSignup] = useState(false);
  const handleCloseThankyouSignup = () => {
    setSignupEmail(null);
    //clearCompanyInfo();
    resetCompanyInfo();
    setShowThankyouSignup(false);
  }
  const handleShowThankyouSignup = () => setShowThankyouSignup(true);

  const handleChangeFormSignup = (event) => {
    const name = event.target.name;
    var value = event.target.value;

    setInputsSignup(values => ({...values, [name]: value, domain: window.location.hostname}));
  }

  const [domainError, setDomainError] = useState(null);
  const submitFormSignup = (event) => {
    event.preventDefault();
    console.log(inputsSignup);
    console.log(inputsGR);
    setSignupEmail(inputsSignup.email_id);
    handleShowEnterOTP();
  }

  //Verify OTP
  const [verifyDisabled, setVerifyDisabled] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [otpError, setOtpError] = useState(null);
  const [otp, setOtp] = useState("");
  const [formSignupEnabled, setFormSignupEnabled] = useState(false);
  const handleChangeOtp = (event) => {
    setOtp(event.target.value);
    if(event.target.value !== ''){
      setVerifyDisabled(false);
    } else {
      setVerifyDisabled(true);
    }
  }
  const submitOTP = (event) => {
    event.preventDefault();
    console.log(otp, signupEmail);
    axios.post(serverConfig.api_base_url + "request_access_submit", {email_id:signupEmail, domain: window.location.hostname, otp:otp})
    .then(response=>{
      console.log('request_access_submit response', response);
      if(response.data.status === 200){
        handleCloseEnterOTP();
        handleShowThankyouSignup();
      } else if(response.data.status >= 400 && response.data.status <= 499){
        setOtpError(response.data.message);
        document.getElementById('resend-otp').style.display = "none";
        document.getElementById('generate-otp').style.display = "block";
        setVerifyDisabled(true);
      } else {
        setOtpError(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const resendOtp = () => {
    let formdata = {...inputsSignup, 'additional_info':{"request_source":"VKFE","min":slider1Val,"max":slider2Val,...inputs, ...inputsGR}};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "request_access_otp", formdata)
    .then(response=>{
      console.log('request_access_otp response', response);
      if(response.data.status === 200){
        setOtp("");
        document.getElementById('otp').value = '';
        document.getElementById('timer').innerHTML = '02:00';
        setOtpError(null);
        setVerifyDisabled(true);
        setResendDisabled(true);
        startTimer();
      } else {
        setOtpError(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [otpGenerated, setOtpGenerated] = useState(false);
  const generateOtp = () => {
    //console.log(inputsSignup);
    //console.log(inputsGR);
    //console.log(inputs);
    let formdata = {...inputsSignup, 'additional_info':{"request_source":"VKFE","min":slider1Val,"max":slider2Val,...inputs, ...inputsGR}};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "request_access_otp", formdata)
    .then(response=>{
      console.log('request_access_otp response', response);
      if(response.data.status === 200){
        setOtpGenerated(true);
        setDomainError(null);
        setOtp("");
        setOtpError(null);
        setVerifyDisabled(true);
        setResendDisabled(true);
        setTimeout(() => {
          document.getElementById('otp').value = '';
          document.getElementById('timer').innerHTML = '02:00';
          document.getElementById('resend-otp').style.display = "block";
          document.getElementById('generate-otp').style.display = "none";
          startTimer();
        }, 10);
      } else {
        setOtpGenerated(false);
        setDomainError(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  function startTimer() {
    console.log(document.getElementById('timer').innerHTML);
    var presentTime = document.getElementById('timer').innerHTML;
    var timeArray = presentTime.split(/[:]+/);
    var m = timeArray[0];
    var s = checkSecond((timeArray[1] - 1));
    if(s==59){m=m-1}
    if(m<0){
      return
    }
    
    document.getElementById('timer').innerHTML =
    ('0' + m).slice(-2) + ":" + s;
    console.log(m, s);
    if(m == '00' && s == '00'){
      console.log('Timer over');
      setResendDisabled(false);
    } else {
      console.log('Timer on');
      setResendDisabled(true);
    }
    setTimeout(startTimer, 1000);
  }
  
  function checkSecond(sec) {
    if (sec < 10 && sec >= 0) {sec = "0" + sec}; // add zero in front of numbers < 10
    if (sec < 0) {sec = "59"};
    return sec;
  }
  /* Signup form - end */

  const handleChangeGR = (event) => {
    const name = event.target.name;
    var value = event.target.value;

    if(event.target.type === 'text' && value !== ''){
      let withoutComma = value.replace(/,/g, '');
      event.target.value = Number(withoutComma).toLocaleString("en-US");
      value = Number(withoutComma).toLocaleString("en-US");
    }

    if(event.target.type === 'number'){
      value = value.replace(/[e\+\-]/gi, "");
    }

    setInputsGR(values => ({...values, [name]: value}));
    setInputsGR((stateInputsGR) => {
      //console.log(stateInputsGR);
      //console.log(Object.values(stateInputsGR).every(x => x === ''));
      if(Object.values(stateInputsGR).every(x => x === '')){ //check if all the GR input fields are filled
        setBtnClearFormGRDisabled(true);
        setBtnSubmitFormGRDisabled(true);
      } else {
        setBtnClearFormGRDisabled(false);
        setBtnSubmitFormGRDisabled(false);
      }
      return stateInputsGR;
    });
  }

  const handleDelete = (event) => {
    var key = event.keyCode || event.charCode;

    if( key == 8 || key == 46 ){
      if(event.target.value === ''){
        setInputsGR(values => ({...values, [event.target.name]: event.target.value}))
      }
    } else if(!(key >= 48 && key <=57) && key!==9 && key!==13){
      event.preventDefault();
    }
  }

  const handleClearGRForm = () => {
    setInputsGR({});
    let elements = document.querySelectorAll('#form-GR input[type="text"]');
    //console.log(elements.length);
    for(let i=0; i<elements.length; i++){
      //console.log(elements[i].name);
      elements[i].value='';
    }
    setBtnClearFormGRDisabled(true);
    setBtnSubmitFormGRDisabled(true);
    setFormSignupEnabled(false);
  }

  const handleClear = () => {
    console.log(inputs);
    handleClearGRForm();
    getInitialMinMax();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputsGR);
    console.log(inputs.ppp_loan_number, vkfeQ12021, vkfeQ22021, vkfeQ32021, vkfe2020);

    //compute - start
    setFormSignupEnabled(true);
    let valGR2019Q1 = inputsGR.GrossReceipt2019Q1 && Number(inputsGR.GrossReceipt2019Q1.replace(/,/g, ''));
    let valGR2019Q2 = inputsGR.GrossReceipt2019Q2 && Number(inputsGR.GrossReceipt2019Q2.replace(/,/g, ''));
    let valGR2019Q3 = inputsGR.GrossReceipt2019Q3 && Number(inputsGR.GrossReceipt2019Q3.replace(/,/g, ''));
    let valGR2019Q4 = inputsGR.GrossReceipt2019Q4 && Number(inputsGR.GrossReceipt2019Q4.replace(/,/g, ''));

    let valGR2020Q4 = inputsGR.GrossReceipt2020Q4 && Number(inputsGR.GrossReceipt2020Q4.replace(/,/g, ''));

    let valGR2021Q1 = inputsGR.GrossReceipt2021Q1 && Number(inputsGR.GrossReceipt2021Q1.replace(/,/g, ''));
    let valGR2021Q2 = inputsGR.GrossReceipt2021Q2 && Number(inputsGR.GrossReceipt2021Q2.replace(/,/g, ''));
    let valGR2021Q3 = inputsGR.GrossReceipt2021Q3 && Number(inputsGR.GrossReceipt2021Q3.replace(/,/g, ''));

    let revenuePercentQ42020 = ((valGR2019Q4 - valGR2020Q4) / valGR2019Q4) * 100;
    let revenuePercentQ12021 = ((valGR2019Q1 - valGR2021Q1) / valGR2019Q1) * 100;
    let revenuePercentQ22021 = ((valGR2019Q2 - valGR2021Q2) / valGR2019Q2) * 100;
    let revenuePercentQ32021 = ((valGR2019Q3 - valGR2021Q3) / valGR2019Q3) * 100;

    console.log(valGR2019Q1,valGR2021Q1, valGR2020Q4, revenuePercentQ42020);

    let prevQ1qualifier = (revenuePercentQ42020 > 20) ? 1 : 0;
    let curQ1qualifier = (revenuePercentQ12021 > 20) ? 1 : 0;

    let prevQ2qualifier = (revenuePercentQ12021 > 20) ? 1 : 0;
    let curQ2qualifier = (revenuePercentQ22021 > 20) ? 1 : 0;

    let prevQ3qualifier = (revenuePercentQ22021 > 20) ? 1 : 0;
    let curQ3qualifier = (revenuePercentQ32021 > 20) ? 1 : 0;

    let elegibilityQ1 = (prevQ1qualifier + curQ1qualifier) >=1 ? 1 : 0;
    let elegibilityQ2 = (prevQ2qualifier + curQ2qualifier) >=1 ? 1 : 0;
    let elegibilityQ3 = (prevQ3qualifier + curQ3qualifier) >=1 ? 1 : 0;

    console.log('vkfe2020', 'vkfeQ12021', 'vkfeQ22021', 'vkfeQ32021');
    console.log(vkfe2020, vkfeQ12021, vkfeQ22021, vkfeQ32021);
    console.log(revenuePercentQ42020);
    console.log(revenuePercentQ12021, prevQ1qualifier, curQ1qualifier, elegibilityQ1);
    console.log(revenuePercentQ22021, prevQ2qualifier, curQ2qualifier, elegibilityQ2);
    console.log(revenuePercentQ32021, prevQ3qualifier, curQ3qualifier, elegibilityQ3);

    let minVal = sliderMinVal;
    let maxVal = sliderMaxVal;
    let sl1Val = vkfe2020;
    let sl2Val = vkfe2020 + (vkfeQ12021 * elegibilityQ1) + (vkfeQ22021 * elegibilityQ2) + (vkfeQ32021 * elegibilityQ3);
    let per1 = ((sl1Val-minVal) / (maxVal-minVal)) * 100;
    let per2 = ((sl2Val-minVal) / (maxVal-minVal)) * 100;

    setSlider1Val(Math.round(sl1Val));
    setSlider2Val(Math.round(sl2Val));
    setSlider1Per(per1);
    setSlider2Per(per2);

    document.getElementById("range1").style.left = `calc(${per1}% - ${document.getElementById('range1').offsetWidth}px)`;

    document.getElementById('slider-track').style.background = `linear-gradient(to right, var(--color2) ${per1}%, var(--color1) ${per1}%, var(--color1) ${per2}%, var(--color2) ${per2}%)`;

    setBtnSubmitFormGRDisabled(true);
    // compute - end

    //set_log
    let formdata = {
      category: "vkfe",
      log_message: {
        action: 'estimate',
        reference: window.location.search.split('?')[1].split("&")[0],
        input: {
          company_name: inputs.company_name,
          loan_no: inputs.ppp_loan_number,
          ...inputsGR
        },
        output: {min:sl1Val, max:sl2Val}
      }
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_log", formdata)
    .then(response=>{
      console.log('set_log response', response);
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <>
      <header>
        <Container fluid>
          <div className="d-flex justify-content-between align-items-center">
            <LogoName target="_blank" />
          </div>
        </Container>
      </header>
      <main>
        <Container fluid>
          <div id="content" className={unauthorized ? 'unauthorized' : null}>
            {!loading && <div className='content-wrap'>
              {!unauthorized ? <Row>
                <Col sm="8">
                  <div id='calculator' className={calculated ? 'calculated bordered' : 'bordered'}>
                    <h1>VentoKredit Estimator</h1>
                    <p>{verifyCompanyInfoText ? verifyCompanyInfoText : <>&nbsp;</>}</p>
                    <section className='d-flex'>
                      <Form id="form-company-info" onSubmit={handleCalculate}>
                        <div className='bg-gray p-3 mb-4'>
                          <Row className='align-items-center'>
                            <Col>
                              <div className='d-flex'>
                                <Form.Label>Company Name<span className='txt-red'>*</span> :</Form.Label>
                                <Form.Control type="text" name="company_name" value={inputs.company_name || ""} onChange={handleChange} required readOnly={calculated} />
                              </div>
                            </Col>
                            <Col>
                              <div className='d-flex'>
                                <Form.Label>PPP Loan Number<span className='txt-red'>*</span> :</Form.Label>
                                <Form.Control type="number" min="0" name="ppp_loan_number" value={inputs.ppp_loan_number || ""} onChange={handleChange} onKeyDown={handleNumber} required readOnly={calculated} />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {!calculated && <div id='company-info-btns' className='d-flex align-items-center justify-content-end'>
                          <p className='txt-red mb-1 mr-4'>{companyInfoError}</p>
                          <Button variant="transparent" type="button" className='mb-2' onClick={clearCompanyInfo} disabled={btnsFormCompanyInfoDisabled}>Clear</Button>
                          <Button variant="primary" type="submit" className='ml-3 mb-2' disabled={btnsFormCompanyInfoDisabled}>Submit</Button>
                        </div>}
                      </Form>
                      <Button className='btn-reset mb-4' onClick={resetCompanyInfo} disabled={companyInfoResetBtnDisabled}><img src={require('./../assets/images/reset-icon.png')} alt="Reset" /></Button>
                    </section>
                    {calculated && <section id='estimate'>
                      <h2>Vento Kredit Estimate</h2>

                      <div className='estimate-range'>
                        <div className='range-container'>
                          <div className='range-values'>
                            <span id="range1">${slider1Val}</span>
                            <span id="range2" style={{'left': `calc(${slider2Per}%)`}}>${slider2Val}</span>
                          </div>
                          <div id="slider-track" className='slider-track'></div>
                          <Form.Range id="slider-1" value={slider1Val} min={sliderMinVal} max={sliderMaxVal} readOnly />
                          <Form.Range id="slider-2" value={slider2Val} min={sliderMinVal} max={sliderMaxVal} readOnly />
                        </div>
                      </div>

                      <Form id="form-GR" onSubmit={handleSubmit}>
                        <section className='bg-gray pt-3 pl-3 pr-3'>
                          <h3 className='mb-3'>Gross Receipts</h3>
                          <p>Use the table below to provide information on gross receipts which are revenue from all sources.</p>
                          <Table borderless className='mb-0'>
                            <thead>
                              <tr>
                                <th width="10%">Year</th>
                                <th width="18%">Q1 (Jan - Mar)</th>
                                <th width="18%">Q2 (Apr - Jun)</th>
                                <th width="18%">Q3 (Jul - Sep)</th>
                                <th width="18%">Q4 (Oct - Dec)</th>
                                {/* <th width="18%">No. of Employees</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>2019</th>
                                <td>
                                  <Form.Control name="GrossReceipt2019Q1" type="text" placeholder="$*" value={inputsGR.GrossReceipt2019Q1 || ""} onChange={handleChangeGR} onKeyDown={handleDelete} required />
                                </td>
                                <td>
                                  <Form.Control name="GrossReceipt2019Q2" type="text" placeholder="$*" value={inputsGR.GrossReceipt2019Q2 || ""} onChange={handleChangeGR} onKeyDown={handleDelete} required />
                                </td>
                                <td>
                                  <Form.Control name="GrossReceipt2019Q3" type="text" placeholder="$*" value={inputsGR.GrossReceipt2019Q3 || ""} onChange={handleChangeGR} onKeyDown={handleDelete} required />
                                </td>
                                <td>
                                  <Form.Control name="GrossReceipt2019Q4" type="text" placeholder="$*" value={inputsGR.GrossReceipt2019Q4 || ""} onChange={handleChangeGR} onKeyDown={handleDelete} required />
                                </td>
                                {/* <td>
                                  <Form.Control name="Employees2019" type="number" min="0" placeholder="Employees*" value={inputsGR.Employees2019 || ""} onChange={handleChangeGR} onKeyDown={handleNumber} required />
                                </td> */}
                              </tr>
                              <tr>
                                <th>2020</th>
                                <td>
                                  <Form.Control name="GrossReceipt2020Q1" type="text" placeholder="$" value={inputsGR.GrossReceipt2020Q1 || ""} onChange={handleChangeGR} onKeyDown={handleDelete} />
                                </td>
                                <td>
                                  <Form.Control name="GrossReceipt2020Q2" type="text" placeholder="$" value={inputsGR.GrossReceipt2020Q2 || ""} onChange={handleChangeGR} onKeyDown={handleDelete} />
                                </td>
                                <td>
                                  <Form.Control name="GrossReceipt2020Q3" type="text" placeholder="$" value={inputsGR.GrossReceipt2020Q3 || ""} onChange={handleChangeGR} onKeyDown={handleDelete} />
                                </td>
                                <td>
                                  <Form.Control name="GrossReceipt2020Q4" type="text" placeholder="$*" value={inputsGR.GrossReceipt2020Q4 || ""} onChange={handleChangeGR} onKeyDown={handleDelete} required />
                                </td>
                                {/* <td>
                                  <Form.Control name="Employees2020" type="number" min="0" placeholder="Employees*" value={inputsGR.Employees2020 || ""} onChange={handleChangeGR} onKeyDown={handleNumber} required />
                                </td> */}
                              </tr>
                              <tr>
                                <th>2021</th>
                                <td>
                                  <Form.Control name="GrossReceipt2021Q1" type="text" placeholder="$*" value={inputsGR.GrossReceipt2021Q1 || ""} onChange={handleChangeGR} onKeyDown={handleDelete} required />
                                </td>
                                <td>
                                  <Form.Control name="GrossReceipt2021Q2" type="text" placeholder="$*" value={inputsGR.GrossReceipt2021Q2 || ""} onChange={handleChangeGR} onKeyDown={handleDelete} required />
                                </td>
                                <td>
                                  <Form.Control name="GrossReceipt2021Q3" type="text" placeholder="$*" value={inputsGR.GrossReceipt2021Q3 || ""} onChange={handleChangeGR} onKeyDown={handleDelete} required />
                                </td>
                                <td>
                                  <Form.Control name="GrossReceipt2021Q4" type="text" placeholder="$" value={inputsGR.GrossReceipt2021Q4 || ""} onChange={handleChangeGR} onKeyDown={handleDelete} />
                                </td>
                                {/* <td>
                                  <Form.Control name="Employees2021" type="number" min="0" placeholder="Employees*" value={inputsGR.Employees2021 || ""} onChange={handleChangeGR} onKeyDown={handleNumber} required />
                                </td> */}
                              </tr>
                            </tbody>
                          </Table>
                        </section>
                        <p className='mt-2 mb-2'>*These numbers are required for a preliminary assessment of your eligibility. You will have an opportunity to validate them at a later point.</p>

                        <Row className="btn-container mt-3">
                          <Col xs="auto">
                            <Button variant="transparent" className='mb-1' onClick={handleClear} disabled={btnClearFormGRDisabled}>Clear</Button>
                          </Col>
                          <Col xs="auto">
                            <Button variant="primary" type="submit" disabled={btnSubmitFormGRDisabled}>Submit</Button>
                          </Col>
                        </Row>
                      </Form>
                    </section>}
                    {/* <section id="signup-faq">
                      <Link to="/" target="_blank" className='txt-link txt-default'>Frequently Asked Questions</Link>
                    </section> */}
                  </div>
                </Col>
                <Col sm="4">
                  <div id='signup' className='bordered'>
                    <div className='video-wrap'>
                      <ReactPlayer url= {videoUrl} playing={true} muted={true} controls={true} width='100%' height='100%' />
                    </div>
                    <h6><b>Sign up with VentoKredit for a detailed ERC calculation</b></h6>
                    <Form id="form-signup" onSubmit={submitFormSignup}>
                      <fieldset disabled={!formSignupEnabled}>
                        <div className='form-wrap'>
                          <Row>
                            <Col><Form.Control type="text" id="name" name="name" placeholder="Contact Name" value={inputsSignup.name || ""} onChange={handleChangeFormSignup} required /></Col>
                          </Row>
                          <Row className='mt-3'>
                            <Col><Form.Control type="email" id="email_id" name="email_id" placeholder="Email ID" value={inputsSignup.email_id || ""} onChange={handleChangeFormSignup} required /></Col>
                            <Col><Form.Control type="text" id="mobile" name="mobile" pattern="[0-9+()]{10,}$" placeholder="Phone No." value={inputsSignup.mobile || ""} onChange={handleChangeFormSignup} required /></Col>
                          </Row>
                        </div>
                        <div className='text-center mt-3'><Button variant="primary" type="submit" className='ml-3'>Sign up</Button></div>
                      </fieldset>
                    </Form>
                    <Modal show={showEnterOTP} onHide={handleCloseEnterOTP} centered className='modal-medium modal-estimator'>
                      <Modal.Header closeButton>
                        <Modal.Title>Verify Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row>
                          <Col>
                            <ul>
                              <li><b>Contact Name :</b> <span className='txt-gray'>{inputsSignup.name}</span></li>
                              <li><b>Email ID :</b> <span className='txt-gray'>{inputsSignup.email_id}</span></li>
                              <li><b>Phone No. :</b> <span className='txt-gray'>{inputsSignup.mobile}</span></li>
                            </ul>
                          </Col>
                          <Col>
                            <ul>
                              <li><b>Company Name :</b> <span className='txt-gray'>{inputs.company_name}</span></li>
                              <li><b>PPP Loan Number :</b> <span className='txt-gray'>{inputs.ppp_loan_number}</span></li>
                            </ul>
                          </Col>
                        </Row>
                        <Table bordered striped>
                          <thead>
                            <tr>
                              <th width="10%">Year</th>
                              <th width="18%">Q1 (Jan - Mar)</th>
                              <th width="18%">Q2 (Apr - Jun)</th>
                              <th width="18%">Q3 (Jul - Sep)</th>
                              <th width="18%">Q4 (Oct - Dec)</th>
                              {/* <th width="18%">No. of Employees</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>2019</th>
                              <td>{inputsGR.GrossReceipt2019Q1 && <>$ {inputsGR.GrossReceipt2019Q1}</>}</td>
                              <td>{inputsGR.GrossReceipt2019Q2 && <>$ {inputsGR.GrossReceipt2019Q2}</>}</td>
                              <td>{inputsGR.GrossReceipt2019Q3 && <>$ {inputsGR.GrossReceipt2019Q3}</>}</td>
                              <td>{inputsGR.GrossReceipt2019Q4 && <>$ {inputsGR.GrossReceipt2019Q4}</>}</td>
                              {/* <td>{inputsGR.Employees2019}</td> */}
                            </tr>
                            <tr>
                              <th>2020</th>
                              <td>{inputsGR.GrossReceipt2020Q1 && <>$ {inputsGR.GrossReceipt2020Q1}</>}</td>
                              <td>{inputsGR.GrossReceipt2020Q2 && <>$ {inputsGR.GrossReceipt2020Q2}</>}</td>
                              <td>{inputsGR.GrossReceipt2020Q3 && <>$ {inputsGR.GrossReceipt2020Q3}</>}</td>
                              <td>{inputsGR.GrossReceipt2020Q4 && <>$ {inputsGR.GrossReceipt2020Q4}</>}</td>
                              {/* <td>{inputsGR.Employees2020}</td> */}
                            </tr>
                            <tr>
                              <th>2021</th>
                              <td>{inputsGR.GrossReceipt2021Q1 && <>$ {inputsGR.GrossReceipt2021Q1}</>}</td>
                              <td>{inputsGR.GrossReceipt2021Q2 && <>$ {inputsGR.GrossReceipt2021Q2}</>}</td>
                              <td>{inputsGR.GrossReceipt2021Q3 && <>$ {inputsGR.GrossReceipt2021Q3}</>}</td>
                              <td>{inputsGR.GrossReceipt2021Q4 && <>$ {inputsGR.GrossReceipt2021Q4}</>}</td>
                              {/* <td>{inputsGR.Employees2021}</td> */}
                            </tr>
                          </tbody>
                        </Table>
                        <div>
                          {!otpGenerated && <>
                            <p className='text-center'>Are these above your best estimates of your quarterly gross receipts? *</p>
                            <p className="txt-red mb-2 text-center">{domainError ? domainError : <>&nbsp;</>}</p>
                            <div className='text-center mb-3'>
                              <Button variant="transparent" onClick={handleCloseEnterOTP} className="mr-4">No</Button>
                              <Button variant="primary" onClick={generateOtp}>Yes</Button>
                            </div>
                            <p className='txt-gray'>*These numbers are required for a preliminary assessment of your eligibility. You will have an opportunity to validate them at a later point. If you need to revise your estimated gross receipts, press “<b>No</b>” to go back to the VKFE Estimator.</p>
                          </>}
                          {otpGenerated && <p className='text-center'>These are our best estimates of quarterly gross receipts*</p>}
                        </div>
                      </Modal.Body>
                      {otpGenerated && <div id="enter-otp">
                        <Modal.Header>
                          <Modal.Title>Enter verification code</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className='text-center'>
                            <p className='text-center mb-4'>We have just sent a verification code to <b>{signupEmail}</b></p>

                            <Form id="requestAccessSubmit" onSubmit={submitOTP}>
                              <div className="input-timer">
                                <Form.Control type="text" id="otp" name="otp" placeholder="Enter OTP" value={otp} onChange={handleChangeOtp} required maxLength="6" />
                                <span id="timer" className="timer">02:00</span>
                              </div>
                              <p className="txt-red mt-1 mb-1 text-center">{otpError ? otpError : <>&nbsp;</>}</p>
                              <p id="resend-otp">Didn't receive code? <Button variant="plain" disabled={resendDisabled} onClick={resendOtp}>Resend</Button></p>
                              <p id="generate-otp"><Button variant="plain" disabled={resendDisabled} onClick={generateOtp}>Re-generate Code</Button></p>
                              <Button variant="primary" type="submit" disabled={verifyDisabled}>Verify</Button>
                            </Form>
                          </div>
                        </Modal.Body>
                      </div>}
                    </Modal>
                    <Modal show={showThankyouSignup} onHide={handleCloseThankyouSignup} centered className='modal-small modal-estimator'>
                      <Modal.Body>
                        <div className='text-center'>
                          <h2>Thank you for signing up to VentoKredit</h2>
                          <p className='text-center mb-4'>You will receive login credentials to below email id <br/><b>{signupEmail}</b><br/> once your account has been provisioned</p>
                          <Button variant="primary" onClick={handleCloseThankyouSignup}>Close</Button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Col>
              </Row> : <div id='unauthorized-access'>
                <h1>Unauthorized Access</h1>
              </div>}
            </div>}
          </div>
        </Container>
      </main>
      <Footer />
    </>
  );
}

export default Estimate;
