import React, {useState, useEffect} from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import axios from "axios";
import { IdAndFaceValidation } from 'idmission-web-sdk'
import LogoName from '../components/LogoName.jsx';

const serverConfig = require("../config/server.js");

const KYCValidation = () => {

  const kyc_token = window.location.href.split("?")[1].split("&")[0];

  const[verifyIDUrl, setVerifyIDUrl] = useState(window.location.href);

  const [isLoading, setIsLoading] = useState(true);
  const[kycTokenValid, setKycTokenValid] = useState(false);
  const[validationSuccess, setValidationSuccess] = useState(false);
  const[idmToken, setIdmToken] = useState(null);
  const[startFaceIdValidation, setStartFaceIdValidation] = useState(false);
  const[errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    axios.post(serverConfig.api_base_url + "get_kyc_token_validity",{token:kyc_token})
    .then(response => {
      console.log('get_kyc_token_validity response', response);
      if(response.status === 200){
        if(response.data.validity === 1){
          setKycTokenValid(true);
          setIsLoading(false);
        } else {
          setKycTokenValid(false);
          setIsLoading(false);
        }
      }
    })
    .catch(err=>console.log("error is",err));

    //axios.post(serverConfig.api_base_url + "get_idmission_token_v2")
    axios.post("https://chia.dev.mtk.dbtez.net/api/get_idmission_token_v2")
    .then(response=>{
      console.log(response);
      if(response.data.access_token) {
        setIdmToken(response.data.access_token);
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }, []);

  return (<>
    <header>
      <Container fluid>
        <div className="d-flex align-items-center">
          <LogoName target="_blank" />
          <b>ID Validation</b>
        </div>
      </Container>
    </header>
    <main>
      <Container fluid>
        <BrowserView>
          <h1 className='text-center'>This page is designed for mobile only use. <br/> Scan the following QR code in the mobile to access this page.</h1>
          <div className='text-center'><QRCode value={verifyIDUrl} size="200" /></div>
        </BrowserView>
        <MobileView id='kyc-content'>
          {isLoading ? <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>
            : <>
              <div id="thankyou-wrapper" className='thankyou-wrapper'>
                <div>
                  <img src={require('./../assets/images/thumbs-up-icon.png')} alt="Thank you" className='mb-3' />
                  <p>Thank you for completing <br/>your ID verification</p>
                </div>
                <div>
                  {/* <Button variant='transparent' onClick={() => window.open('','_self').close()} className='mx-auto'>Close</Button> */}
                  <p className='txt-gray mt-3'>Please go back to desktop to continue</p>
                </div>
              </div>

              <div id="button-content" className="mt-5">
                {kycTokenValid ? <Button id="captureSelfieDL" variant="icon-text" className='mx-auto' onClick={() => setStartFaceIdValidation(true)}><img src={require('./../assets/images/doc-click-icon.png')} /> Take selfie and picture<br/> of your DL</Button>
                : <p className='txt-red text-center'>Link is invalid. To re-initiate, go back to the application and refresh the page.</p>
                }
              </div>

              {startFaceIdValidation && (
                <IdAndFaceValidation
                  submissionToken={idmToken} 
                  idCaptureRequirement="idCard"
                  //idCardAutoCaptureScoreThreshold="0.01"
                  //idCardFocusScoreThreshold="0.01"
                  onExitCapture={() => setStartFaceIdValidation(false)}
                  onDenied={(submissionResponse) => {
                    alert('Error capturing selfie and DL. Please retry')
                  }}
                  onComplete={(submissionResponse, submissionRequest) => {
                    console.log('Your ID has been validated', submissionResponse);
                    console.log('submissionRequest', submissionRequest);
                    //alert('Your PP has been validated, proceeding to PP response evaluation.');
      
                    if ('FAIL' === submissionResponse.status || submissionResponse.status.statusCode != "000") {
                      console.log("Error capturing Selfie and DL. Please retry");
                      //setErrorPassport(true);
                      alert("Error capturing Selfie and DL. Please retry");
                      setStartFaceIdValidation(false);
                    } else {
                      console.log('Selfie and DL captured successfully.');
                      let extractedData = submissionResponse.responseCustomerData.extractedIdData;
      
                      if (extractedData.idNumber != null && extractedData.idIssueDate != null && extractedData.idIssueCountry != null && extractedData.idExpirationDate != null) {
                        //setSuccessPassportDetails(true);
                      } else {
                        //setErrorPassportDetails(true);
                        alert("Couldn't read DL details. Please try again");
                      }
      
                      console.log(submissionResponse);
      
                      let formdata = {
                        token: window.location.href.split("?")[1].split("&")[0],
                        data: submissionResponse
                      };
                      console.log(formdata);
      
                      axios.post(serverConfig.api_base_url + "set_kyc_data",formdata)
                      .then(response=>{
                        console.log('set_kyc_data response', response);
                        if(response.data.status === 200) {
                          console.log("success");
                          document.getElementById('thankyou-wrapper').style.display = "flex";
                          document.getElementById('button-content').style.display = "none";
                        } else if(response.data.status === 407){
                          //handleLogout();
                        } else {
                          alert(response.data.message);
                        }
                      })
                      .catch(err=>console.log("error is",err));
      
                      //setErrorPassport(false);
                      setStartFaceIdValidation(false);
                    }
                  }} 
                />
              )}
            </>
          }
        </MobileView>
      </Container>
    </main>
  </>);
}

export default KYCValidation;
