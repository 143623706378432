import React, {useState, useEffect} from 'react';
import { Container, Tab, Nav, Col, Row } from 'react-bootstrap';
import axios from "axios";
import Header from '../components/Header';
import UserService from '../services/UserService';

import Users from './../components/Users';
import VideoList from '../components/VideoList';
import VideoMapping from './../components/VideoMapping';
import VkfeLog from '../components/VkfeLog';

const serverConfig = require("../config/server.js");

const Admin = () => {

  const [token, setToken] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [userPrivileged, setUserPrivileged] = useState(false);
  const [videoPrivileged, setVideoPrivileged] = useState(false);
  const [vkfeLogPrivileged, setVkfeLogPrivileged] = useState(false);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    //window.spartezSupportChat.sso.logout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "get_user_token",{jwt:jwt})
    .then(response=>{
      console.log('get_user_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined){
      /* check_user_privilege - read_user_list */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:token, privilege:'read_user_list'})
      .then(response=>{
        console.log("read_user_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setUserPrivileged(false);
          } else if(response.data.is_privileged === 1){
            setUserPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - naics_code_admin */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:token, privilege:'naics_code_admin'})
      .then(response=>{
        console.log("naics_code_admin privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setVideoPrivileged(false);
          } else if(response.data.is_privileged === 1){
            setVideoPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
        setIsLoading(false);
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - vkfe_log */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:token, privilege:'vkfe_log'})
      .then(response=>{
        console.log("vkfe_log privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setVkfeLogPrivileged(false);
          } else if(response.data.is_privileged === 1){
            setVkfeLogPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
        setIsLoading(false);
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token]);

  return (
    <>
      <Header />
      <main>
        <Container fluid>
        {isLoading ? <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>
        : <>{(!userPrivileged && !videoPrivileged) ? <h2 className='txt-red text-center'>Unauthorized Access</h2> :
        <Tab.Container id="left-tabs-example" defaultActiveKey="userManagement">
          <Row>
            <Col sm={3} className='mb-3'>
              <Nav variant="pills" className="flex-column">
                {userPrivileged && <Nav.Item>
                  <Nav.Link eventKey="userManagement">User Management</Nav.Link>
                </Nav.Item>}
                {videoPrivileged && <><Nav.Item>
                  <Nav.Link eventKey="videoList">Video List</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="videoMapping">Video Mapping</Nav.Link>
                </Nav.Item></>}
                {vkfeLogPrivileged && <Nav.Item>
                  <Nav.Link eventKey="vkfeLog">VKFE Log</Nav.Link>
                </Nav.Item>}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                {userPrivileged && <Tab.Pane eventKey="userManagement">
                  <Users />
                </Tab.Pane>}
                {videoPrivileged && <><Tab.Pane eventKey="videoList">
                  <VideoList />
                </Tab.Pane>
                <Tab.Pane eventKey="videoMapping">
                  <VideoMapping />
                </Tab.Pane></>}
                {vkfeLogPrivileged && <Tab.Pane eventKey="vkfeLog">
                  <VkfeLog />
                </Tab.Pane>}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>}</>
        }
        
        </Container>
      </main>
    </>
  );
}

export default Admin;