import React, {useState, useEffect} from 'react';
import { Row, Col, Button, Form} from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const EstimatorSecure = () => {

  const [calculated, setCalculated] = useState(false);

  const [inputs, setInputs] = useState({});

  const [btnsFormCompanyInfoDisabled, setBtnsFormCompanyInfoDisabled] = useState(true);

  const [sliderMinVal, setSliderMinVal] = useState(null);
  const [sliderMaxVal, setSliderMaxVal] = useState(null);
  const [slider1Val, setSlider1Val] = useState("");
  const [slider2Val, setSlider2Val] = useState("");
  const [slider2Per, setSlider2Per] = useState(null);

  const [companyName, setCompanyName] = useState(null);
  const [companyInfoError, setCompanyInfoError] = useState(null);
  const [companyInfoResetBtnDisabled, setCompanyInfoResetBtnDisabled] = useState(true);

  useEffect(() => {
    resetCompanyInfo();

    //set_log
    let formdata = {
      category: "vkfe",
      log_message: {
        action: 'visited',
        reference: window.location.search.split('?')[1]
      }
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_log", formdata)
    .then(response=>{
      console.log('set_log response', response);
    })
    .catch(err=>console.log("error is",err));
  }, []);

  const getInitialMinMax = () => {
    console.log(inputs);

    axios.post(serverConfig.api_base_url + "get_vkfe_min_max", {loan_number:inputs.ppp_loan_number})
    .then(response=>{
      console.log('get_vkfe_min_max response', response);
      if(response.data.status === 200){
        setCalculated(true);
        setCompanyInfoError(null);
        setCompanyInfoResetBtnDisabled(false);
        let data = JSON.parse(response.data.items);
        console.log(data);

        let minVal = 0;
        let maxVal = data.maxval + 200000;
        let sl1Val = data.minval;
        let sl2Val = data.maxval;
        let per1 = ((sl1Val-minVal) / (maxVal-minVal)) * 100;
        let per2 = ((sl2Val-minVal) / (maxVal-minVal)) * 100;

        console.log(minVal);
        console.log(maxVal);
        console.log(sl1Val);
        console.log(sl2Val);
        console.log(per1);
        console.log(per2);

        setSliderMinVal(minVal);
        setSliderMaxVal(maxVal);
        setSlider1Val(Math.round(sl1Val));
        setSlider2Val(Math.round(sl2Val));
        setSlider2Per(per2);
        setCompanyName(data.companyName);

        setTimeout(() => {
          document.getElementById("range1").style.left = `calc(${per1}% - ${document.getElementById('range1').offsetWidth}px)`;
          document.getElementById('slider-track').style.background = `linear-gradient(to right, var(--color2) ${per1}%, var(--color1) ${per1}%, var(--color1) ${per2}%, var(--color2) ${per2}%)`;
        }, 100);
      } else {
        setCompanyInfoError(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const handleCalculate = (event) => {
    event.preventDefault();
    getInitialMinMax();

    //set_log
    let formdata = {
      category: "vkfe",
      log_message: {
        action: 'submitted',
        reference: window.location.search.split('?')[1],
        input: {
          company_name: inputs.company_name,
          loan_no: inputs.ppp_loan_number
        }
      }
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_log", formdata)
    .then(response=>{
      console.log('set_log response', response);
    })
    .catch(err=>console.log("error is",err));
  }

  const clearCompanyInfo = (event) => {
    setCompanyInfoResetBtnDisabled(true);
    setInputs({});
    let elements = document.querySelectorAll('#form-company-info input[type="text"]');
    for(let i=0; i<elements.length; i++){
      elements[i].value='';
    }
    document.querySelectorAll('#form-company-info input[type="number"]').value='';
    
    setCalculated(false);
    setCompanyInfoError(null);
    setBtnsFormCompanyInfoDisabled(true);
  }

  const resetCompanyInfo = () => {
    setCompanyName(null);
    setCompanyInfoResetBtnDisabled(true);
    setInputs({});
    setBtnsFormCompanyInfoDisabled(true);
    setCalculated(false);
    let elements = document.querySelectorAll('#form-signup input[type="text"]');
    for(let i=0; i<elements.length; i++){
      elements[i].value='';
    }
    document.querySelectorAll('#form-signup input[type="email"]').value='';
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
    setInputs((stateInputs) => {
      console.log(stateInputs);
      let totalInputsLength = document.querySelectorAll('#form-company-info input[type="number"]').length;
      console.log(Object.values(stateInputs).every(x => x !== ''), totalInputsLength, Object.keys(stateInputs).length);
      if((totalInputsLength === Object.keys(stateInputs).length) && (Object.values(stateInputs).every(x => x !== ''))){ //check if all the company input fields are filled
        setBtnsFormCompanyInfoDisabled(false);
      } else {
        setBtnsFormCompanyInfoDisabled(true);
      }
      return stateInputs;
    });
  }

  const handleNumber = (event) => {
    if(["e", "E", "+", "-"].includes(event.key)){
      event.preventDefault();
    }
  }

  return (
    <>
      <div className='content-wrap'>
        <div id='calculator' className={calculated ? 'calculated bordered' : 'bordered'} style={{height: '300px', background: 'none'}}>
          <section className='d-flex'>
            <Form id="form-company-info" onSubmit={handleCalculate}>
              <div className='bg-gray p-3 mb-4'>
                <Row className='align-items-center'>
                  <Col>
                    <div className='d-flex'>
                      <Form.Label>PPP Loan Number<span className='txt-red'>*</span> :</Form.Label>
                      <Form.Control type="number" min="0" name="ppp_loan_number" value={inputs.ppp_loan_number || ""} onChange={handleChange} onKeyDown={handleNumber} required readOnly={calculated} />
                    </div>
                  </Col>
                </Row>
                {companyName && <div className='mt-2'>
                  <Form.Label>Company Name :</Form.Label> {companyName}
                </div>}
              </div>
              {!calculated && <div id='company-info-btns' className='d-flex align-items-center justify-content-end'>
                <p className='txt-red mb-1 mr-4'>{companyInfoError}</p>
                <Button variant="transparent" type="button" className='mb-2' onClick={clearCompanyInfo} disabled={btnsFormCompanyInfoDisabled}>Clear</Button>
                <Button variant="primary" type="submit" className='ml-3 mb-2' disabled={btnsFormCompanyInfoDisabled}>Submit</Button>
              </div>}
            </Form>
            <Button className='btn-reset mb-4' onClick={resetCompanyInfo} disabled={companyInfoResetBtnDisabled}><img src={require('./../assets/images/reset-icon.png')} alt="Reset" /></Button>
          </section>
          {calculated && <section id='estimate'>
            <h2>Vento Kredit Estimate</h2>
            <div className='estimate-range'>
              <div className='range-container'>
                <div className='range-values'>
                  <span id="range1">${slider1Val}</span>
                  <span id="range2" style={{'left': `calc(${slider2Per}%)`}}>${slider2Val}</span>
                </div>
                <div id="slider-track" className='slider-track'></div>
                <Form.Range id="slider-1" value={slider1Val} min={sliderMinVal} max={sliderMaxVal} readOnly />
                <Form.Range id="slider-2" value={slider2Val} min={sliderMinVal} max={sliderMaxVal} readOnly />
              </div>
            </div>
          </section>}
        </div>
      </div>
    </>
  );
}

export default EstimatorSecure;
