import React, {useState} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {

  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100){
      setVisible(true)
    } 
    else if (scrolled <= 100){
      setVisible(false)
    }
  };
  
  window.addEventListener('scroll', toggleVisible);

  return (
    <footer>
      <Container fluid>
        <Row className='footer-primary'>
          <Col sm="4" className='footer-logo'>
            <Link to="/" className='logo' target="_blank"><img src={require('./../assets/images/ventokredit-logo.png')} alt="VentoKredit Logo" /></Link>
          </Col>
          <Col sm="8">
            <Row>
              <Col sm="4">
                <h3>QUICK LINKS</h3>
                <ul>
                  <li><Link to="/" target="_blank">About Us</Link></li>
                  <li><Link to="/" target="_blank">FAQs</Link></li>
                  <li><Link to="/" target="_blank">Contact</Link></li>
                </ul>
              </Col>
              <Col sm="8">
                <h3>CONTACT INFORMATION</h3>
                <p><a href="mailto:questions@ventokredit.com">questions@ventokredit.com</a></p>
                <p>20660  Stevens Creek Boulevard #249, Cupertino, CA</p>
                <p>(408) 547-7124</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='copyright'>
          <span>&copy; 2023, VentoKredit. All rights reserved.</span>
        </div>
      </Container>
      <Button style={{display: visible ? 'inline-block' : 'none'}} id='btn-go-top' onClick={e => window.scrollTo({top: 0, behavior: 'smooth'})} title="Go to top"><i className="fas fa-angle-up"></i></Button>
    </footer>
  );
}

export default Footer;
