import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Button, Modal} from 'react-bootstrap';
import axios from "axios";
import Header from '../components/Header';
import FormSubmit from '../components/FormSubmit';
import UserService from '../services/UserService';
import ClientChat from '../components/ClientChat';
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const serverConfig = require("../config/server.js");

const Signup = () => {

  const navigate = useNavigate();

  const [token, setToken] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [userPrivileged, setUserPrivileged] = useState(false);

  const [showModalResetData, setShowModalResetData] = useState(false);
  const handleCloseModalResetData = () => setShowModalResetData(false);
  const handleShowModalResetData = () => setShowModalResetData(true);
  const [showModalPopulateData, setShowModalPopulateData] = useState(false);
  const handleCloseModalPopulateData = () => setShowModalPopulateData(false);
  const handleShowModalPopulateData = () => setShowModalPopulateData(true);

  const [entityName, setEntityName] = useState('');
  const [step, setstep] = useState(1);
  const [stepNumber, setStepNumber] = useState(1);

  const [stepLength, setStepLength] = useState(null);

  const [entityGroups, setEntityGroups] = useState([]);
  const [firstGroupName, setFirstGroupName] = useState('');

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    //window.spartezSupportChat.sso.logout();
  }
  
  const nextStep = () => {
    //console.log(step, step+1, entityGroups[step].group);
    if(entityGroups[step+1] !== undefined){
      if(entityGroups[step].group === entityGroups[step+1].parent_group){
        //console.log('aa1', entityGroups[step+1].group);
        setstep(step + 2);
        setEntityName(entityGroups[step+1].group);
      } else {
        //console.log('aa2', entityGroups[step].group);
        setstep(step + 1);
        setEntityName(entityGroups[step].group);
      }
    } else {
      //console.log('aa2', entityGroups[step].group);
      setstep(step + 1);
      setEntityName(entityGroups[step].group);
    }
  };

  const prevStep = () => {
    console.log(step-2, entityGroups[step-2].group);
    if(entityGroups[step-2].group === entityGroups[step-1].parent_group){
      //console.log('aa1', entityGroups[step-3].group);
      setstep(step - 2);
      setEntityName(entityGroups[step-3].group);
    } else {
      //console.log('aa2', step - 1, entityGroups[step-2].group);
      setstep(step - 1);
      setEntityName(entityGroups[step-2].group);
    }
  };

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "get_user_token",{jwt:jwt})
    .then(response=>{
      console.log('get_user_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const getCompletedStatusFn = () => {
    axios.post(serverConfig.api_base_url + "get_entity_group",{token: token})
    .then(response=>{
      console.log("get_entity_group response:", response);
      if(response.data.status === 200) {
        setIsLoading(false);
        if(response.data.items !== null){
          let res = JSON.parse(response.data.items);
          console.log(JSON.parse(response.data.items));
          console.log(JSON.parse(response.data.items).length);
          setStepLength(JSON.parse(response.data.items).length);

          let excludeAddDoc = res.filter(x => x.group !== 'additional_documents');
          if(excludeAddDoc.every(el => el.completed_percent === 100 || el.completed_percent === null)){
            setSubmitDisabled(false);
          } else {
            setSubmitDisabled(true);
          }
          
          console.log(res);
          res.sort(function(a,b){
            return a.seq_no - b.seq_no;
          });
          console.log(res);
          console.log(res.length, res[res.length-1].group);
          if(res[res.length-1].group === "engagement_agreement"){
            res.push({
              "group": "thank_you_ea",//thankyou submit engagement agreement
              "label": "Thank You",
              "parent_group": null,
              "completed_percent": null
            });
          } else if(res[res.length-1].group === "submit_for_onboarding"){
            res.push({
              "group": "thank_you_so",//thankyou submit onboarding
              "label": "Thank You",
              "parent_group": null,
              "completed_percent": null
            });
          } else {
            res.push({
              "group": "thank_you",//thankyou submit final
              "label": "Thank You",
              "parent_group": null,
              "completed_percent": null
            });
          }
          console.log(res);
          setEntityGroups(res);
          if(res[1].parent_group){
            setFirstGroupName(res[1].group);
            setStepNumber(2);
          } else {
            setFirstGroupName(res[0].group);
            setStepNumber(1);
          }
        }

        console.log(response.data.submitted_status);
        if(response.data.submitted_status === 1){
          setFormSubmitted(true);
        } else if(response.data.submitted_status === 0){
          setFormSubmitted(false);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    getToken();

    if (window.performance) {
      if (performance.navigation.type == 1) {//on refresh clear session storage
        sessionStorage.clear();
      }
    }
  }, []);

  useEffect(() => {
    console.log(firstGroupName);
    //setstep(1);
    setstep(stepNumber);
    setEntityName(firstGroupName);
  }, [firstGroupName]);

  useEffect(() => {
    console.log('token and entity changed');
    console.log('Token', token, sessionStorage.getItem('token'));
    window.scrollTo(0, 0);

    if(token !== null && token !== undefined){
      /* check_user_privilege - access_request_list - redirect to applications page */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:token, privilege:'access_request_list'})
      .then(response=>{
        console.log("access_request_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            getCompletedStatusFn();
          } else if(response.data.is_privileged === 1){
            navigate("/applications");
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      /* axios.post(serverConfig.api_base_url + "get_jira_token",{token:token})
      .then(response=>{
        console.log("get_jira_token response:", response);
        if(response.status === 200){
          console.log(response.data);
          try {
            window.spartezSupportChat.sso.login(response.data);
          } catch (exception){
            //handle exception
            console.error(exception);
            console.error(exception.message);
          }
        }
      })
      .catch(err=>console.log("error is",err)); */

      const script = document.createElement('script');
      script.src = "https://tfaisuper.blob.core.windows.net/$web/supervity-chat-widget.js?token=ff64b6ec7a532383318e01f9&orgId=1697&collection=1697_Vento2&chatFontSize=15px&brandIcon=https://i.ibb.co/D1wnWXW/Amberron-new.png&chatWidgetCloseIconColor=%231d262e&showUserIcon=true&botIcon=https://i.ibb.co/D1wnWXW/Amberron-new.png&brandIconSize=60&headerMsg2=Ask%20Manatoko&brandColor=%234C4B4B&userChatBgColor=%234C4B4B";
      script.type = "module";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      }
    }
  }, [entityName, token]);

  useEffect(() => {
    console.log('token changed');
    console.log('Token', token, sessionStorage.getItem('token'));
    
    if(token !== null && token !== undefined){
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token: token, privilege: 'reset_data'})
      .then(response=>{
        console.log("get_user_list privilege response:", response);
        if(response.data.status === 200) {
          console.log('success');
          console.log(response.data.is_privileged);
          if(response.data.is_privileged === 0){
            setUserPrivileged(false);
          } else if(response.data.is_privileged === 1){
            setUserPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token]);

  const handleNav = (stepNum, stepName) => {
    console.log(stepNum, stepName);
    if(entityGroups[stepNum].group === entityGroups[stepNum+1].parent_group){
      console.log('aa1');
      setstep(stepNum + 2);
      setEntityName(entityGroups[stepNum+1].group);
    } else {
      console.log('aa2');
      setstep(stepNum + 1);
      setEntityName(stepName);
    }
  }

  const resetForm = () => {
    console.log('reset form');
    axios.post(serverConfig.api_base_url + "testuser_reset_data",{token: token})
    .then(response=>{
      console.log('testuser_reset_data response', response);
      if(response.data.status === 200) {
        console.log('success');
        handleCloseModalResetData();
        window.location.reload(false);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const populateForm = () => {
    console.log('populate form');
    axios.post(serverConfig.api_base_url + "testuser_populate_data",{token: token})
    .then(response=>{
      console.log('testuser_populate_data response', response);
      if(response.data.status === 200) {
        console.log('success');
        handleCloseModalPopulateData();
        window.location.reload(false);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  return(<>
    <div style={isLoading ? {position: 'relative', overflow: 'hidden', height: '100vh'} : {}}>
      {isLoading && <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>}
      <Header />
      <main>
        <Container fluid>
          <BrowserView>
            <Row>
              <Col sm={3}>
                <div className='sidebar-wrapper'>
                  <ul className='sidebar-menu'>
                    {entityGroups.map((item, index) => {
                      var status;
                      if(item.completed_percent !== null){
                        if(item.completed_percent !== 0){
                          if(item.completed_percent == '100'){
                            status = 'C'
                          } else {
                            status = 'I'
                          }
                        } else {
                          status = 'E'
                        }
                      } else {
                        status = 'N'
                      }
                      return (
                      <>
                      {item.parent_group === null &&
                        <li key={index} className={entityGroups[step] !== undefined && (item.group == entityName || item.group == entityGroups[step-1].parent_group) ? 'active '+status : status}>
                          <span className='step-status' style={{'--per': item.completed_percent}}></span>
                          <span className='step-heading' onClick={() => handleNav(index, item.group)}>{item.label}</span>
                          {entityGroups.map((subitem, subindex) => {
                            var subStatus;
                            if(subitem.completed_percent !== null){
                              if(subitem.completed_percent !== 0){
                                if(subitem.completed_percent == '100'){
                                  subStatus = 'C'
                                } else {
                                  subStatus = 'I'
                                }
                              } else {
                                subStatus = 'E'
                              }
                            } else {
                              subStatus = 'N'
                            }
                            return (<>
                              {item.group === subitem.parent_group && <>
                                {/* {entityName}, {subitem.parent_group}, {item.group} */}
                                <ul>
                                  {subitem.parent_group != null &&
                                    <li key={subindex} className={entityName === subitem.group ? 'active '+subStatus : subStatus}>
                                      <div>
                                        <span className='step-status' style={{'--per': subitem.completed_percent}}></span>
                                        <span className='step-heading' onClick={() => handleNav(subindex, subitem.group)}>{subitem.label}</span>
                                      </div>
                                    </li>
                                  }
                                </ul>
                              </>}
                            </>)
                          })}
                        </li>
                      }
                      </>
                    )})}
                  </ul>
                  
                  {userPrivileged && <>
                    <div className="sidebar-buttons">
                      <Button onClick={handleShowModalResetData}>Reset</Button>
                      <Button onClick={handleShowModalPopulateData}>Populate</Button>
                    </div>
                    <Modal className='modal-confirmation' show={showModalResetData} onHide={handleCloseModalResetData} backdrop="static" keyboard={false} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Reset Data</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>Do you want to proceed with reset data?</Modal.Body>
                      <Modal.Footer>
                        <Button variant="transparent" onClick={handleCloseModalResetData}>
                          Cancel
                        </Button>
                        <Button variant="primary" onClick={resetForm}>Reset</Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal className='modal-confirmation' show={showModalPopulateData} onHide={handleCloseModalPopulateData} backdrop="static" keyboard={false} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Populate Data</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>Do you want to proceed with populate data?</Modal.Body>
                      <Modal.Footer>
                        <Button variant="transparent" onClick={handleCloseModalPopulateData}>
                          Cancel
                        </Button>
                        <Button variant="primary" onClick={populateForm}>Populate</Button>
                      </Modal.Footer>
                    </Modal>
                  </>}
                  
                  {/* <div className='powered-by'>
                    <div>
                      <span>Powered by:</span>
                      <div className='ml-2'><a href='/' target='_blank'><img src={require('./../assets/images/ventokredit-logo.png')} alt="VentoKredit Logo" /></a></div>
                    </div>
                  </div> */}
                </div>
              </Col>
              <Col sm={9}>
                {/* {entityName},{step}, {stepNumber} */}
                {(token && entityName) &&
                  <FormSubmit prevStep={prevStep} nextStep={nextStep} token={token} entityName={entityName} stepNum={step} stepNumber={stepNumber} stepLength={stepLength} handleLogout={handleLogout} refreshSidebarCompletedStatus={getCompletedStatusFn} submitDisabled={submitDisabled} formSubmitted={formSubmitted} handleNav={handleNav} entityGroups={entityGroups} />
                }
              </Col>
            </Row>
          </BrowserView>
          <MobileView>
            <h1 className='text-center'>This site is optimized for desktop. Please login from desktop for a better experience.</h1>
          </MobileView>
        </Container>
        {/* {formSubmitted && <ClientChat mToken={sessionStorage.getItem('token')} />} */}
      </main>
    </div>
    {/* {formSubmitted && <script data-jsd-embedded data-key="1a9d524c-305d-42e6-89ee-d8fcc06f554d" data-base-url="https://jsd-widget.atlassian.com" src="https://jsd-widget.atlassian.com/assets/embed.js"></script>} */}
    {/* <div id="jira-chat" title='Support Chat'><div id="jira-chat-wrap"></div></div> */}
  </>)
}

export default Signup;
