import React, {useState, useEffect} from 'react';
import { Button, Form, Row, Col} from 'react-bootstrap';
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import UserService from '../services/UserService';
import { useNavigate } from "react-router-dom";

const serverConfig = require("../config/server.js");

const VkfeLog = () => {

  const [token, setToken] = useState(null);

  const [vkfeLogPrivileged, setVkfeLogPrivileged] = useState(false);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    //window.spartezSupportChat.sso.logout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "get_user_token",{jwt:jwt})
    .then(response=>{
      console.log('get_user_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined){
      /* check_user_privilege - vkfe_log */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:token, privilege:'vkfe_log'})
      .then(response=>{
        console.log("vkfe_log privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setVkfeLogPrivileged(false);
            navigate("/");
          } else if(response.data.is_privileged === 1){
            setVkfeLogPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token]);

  const [keyword, setKeyword] = useState("");
  const [noOfDays, setNoOfDays] = useState(7);

  const handleDelete = (event) => {
    console.log('handleDelete');

    if(event.target.type==='number'){
      if(["e", "E", "+", "-"].includes(event.key)){
        event.preventDefault();
      }
    }
  }

  const handleSubmit = (event) => {
    setData([]);
    setIsLoading(true);
    event.preventDefault();
    console.log(keyword, noOfDays);
    /* get_vkfe_log */
    axios.post(serverConfig.api_base_url + "get_vkfe_log",{token:token, search_str:keyword, no_of_days:noOfDays})
    .then(response=>{
      console.log("get_vkfe_log response:", response);
      if(response.data.status === 200){
        let items = JSON.parse(response.data.items);
        console.log(items);
        if (items !== null){
          setData(items.reverse());//in descending order of stamp
        }
        setIsLoading(false);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const tabledata = {
    columns: [
      { label: 'Stamp', field: 'stamp', sort: false, width: 250 },
      { label: 'Module', field: 'module', sort: true, width: 500 },
      { label: 'Logs', field: 'logs', sort: true },
    ],
    rows: data,
  };

  return (
    <>
      {isLoading && <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>}
      {vkfeLogPrivileged && <>
        <div className='page-heading'>
          <h1>VKFE Log</h1>
        </div>
        <section className='mb-3'>
          <Form onSubmit={handleSubmit}>
            <Row className='align-items-end'>
              <Col>
                <Form.Label>Filter</Form.Label>
                <Form.Control type="text" name="keyword" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
              </Col>
              <Col>
                <Form.Label>No. of days</Form.Label>
                <Form.Control type="number" min="0" name="noOfDays" value={noOfDays} onKeyDown={handleDelete} onChange={(e) => setNoOfDays(e.target.value)} required />
              </Col>
              <Col xs="auto"><Button variant="primary" type="submit">Search</Button></Col>
            </Row>
          </Form>
        </section>
        
        {data.length > 0 && <section>
          <div className='datatable table-layout-auto'>
            <MDBDataTable striped borderless data={tabledata} entries={10} entriesOptions={[ 10, 20, 30 ]} searching={false} />
          </div>
        </section>}
      </>}
    </>
  );
}

export default VkfeLog;