import React, {useState, useEffect} from 'react';
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import UserService from '../services/UserService';
import { useNavigate } from "react-router-dom";

const serverConfig = require("../config/server.js");

const VideoMapping = () => {

  const [token, setToken] = useState(null);
  const [appDataGridRender, setAppDataGridRender] = useState();
  const [videoList, setVideoList] = useState([]);
  const [actions, setActions] = useState(false);

  const [userPrivileged, setUserPrivileged] = useState(false);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    //window.spartezSupportChat.sso.logout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "get_user_token",{jwt:jwt})
    .then(response=>{
      console.log('get_user_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined){
      /* check_user_privilege - naics_code_admin */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:token, privilege:'naics_code_admin'})
      .then(response=>{
        console.log("naics_code_admin privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setUserPrivileged(false);
            navigate("/");
          } else if(response.data.is_privileged === 1){
            setUserPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token]);

  useEffect(() => {
    if(token !== null && token !== undefined){
     //get_vkfe_video_list
     if(userPrivileged){
      axios.post(serverConfig.api_base_url + "get_vkfe_video_list",{token:token})
      .then(response=>{
        console.log('get_vkfe_video_list response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            console.log(JSON.parse(response.data.items));
            setVideoList(JSON.parse(response.data.items));
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
      }
    }
  }, [token, userPrivileged]);

  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    console.log('userPrivileged', userPrivileged);
    
    if(token !== null && token !== undefined){
      if(userPrivileged) {
        console.log('token', token);
        /* get_vkfe_naics_code_list */
        axios.post(serverConfig.api_base_url + "get_vkfe_naics_code_list",{token:token})
        .then(response=>{
          console.log("get_vkfe_naics_code_list response:", response);
          setIsLoading(false);
          if(response.data.status === 200){
            let items = JSON.parse(response.data.items);
            //console.log(items);
            if (items !== null || items !== undefined){
              let appData = [];
              items.map((item, index) => {
                return (<>
                  {item.actions = (<>
                  {videoList.length !== 0 && <>
                    <select id={item.naics_code} value={item.video_desc ? item.video_desc : ''} onChange={(e) => handleTagVideo(item.naics_code, e)}>
                      <option value='Tag Video'>Tag Video</option>
                      {videoList.map((vitem, index) => <option key={index} value={vitem.video_desc}>{vitem.video_desc}</option>)}
                    </select>
                    </>}
                  </>)}

                  {appData.push(item)}
                </>);
              });
              //console.log(appData);
              setAppDataGridRender(appData);
            }
          } else if(response.data.status >= 400 && response.data.status <= 499){
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      }
    }
  }, [token, videoList, userPrivileged, actions]);

  const handleTagVideo = (naicsCode, event) => {
    console.log(event.target.id, naicsCode, event.target.value);
    if(event.target.value !== 'Tag Video'){
      setIsLoading(true);
      axios.post(serverConfig.api_base_url + "set_vkfe_naics_update_video",{token:token, vkfe_naics_code:naicsCode, vkfe_name: event.target.value})
      .then(response=>{
        console.log('set_vkfe_naics_update_video response', response);
        if(response.data.status === 200){
          console.log(response.data);
          setActions(!actions);//refresh records table
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  const data = {
    columns: [
      { label: 'NAICS Code', field: 'naics_code', sort: true, width: 250 },
      { label: 'NAICS Title', field: 'naics_title', sort: false, width: 250 },
      { label: 'Actions', field: 'actions', sort: 'disabled', width: 250 },
    ],
    rows: appDataGridRender,
  };

  return (
    <>
      {isLoading && <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>}
      <div className='page-heading'>
        <h1>Video Mapping</h1>
      </div>
      <section>
        <div className='datatable table-layout-auto'>
          <MDBDataTable striped borderless data={data} entries={10} entriesOptions={[ 10, 20, 30 ]} searchLabel="Type here" />
        </div>
      </section>
    </>
  );
}

export default VideoMapping;