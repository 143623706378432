import React, {useState, useEffect} from 'react';
import { Modal, Button, Table, Row, Col } from 'react-bootstrap';
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import FileSaver from 'file-saver';

const serverConfig = require("../config/server.js");

function AppDataGrid({show, refreshAppList, getAppCount, token, isUserPrivileged, getToken}) {
  
  //const mToken = sessionStorage.getItem('mToken');
  const mToken = token;

  const [isLoading, setIsLoading] = useState(false);

  const [showApproveBtn, setShowApproveBtn] = useState(false);
  const [accessRequestData, setAccessRequestData] = useState({});

  const [showDetails, setShowDetails] = useState(false);
  const handleCloseDetails = () => setShowDetails(false);
  
  const handleShowDetails = (item) => {
    console.log(item);
    setAccessRequestData(item);
    if(item.status === 'Approved'){
      setShowApproveBtn(false);
    } else if(item.status === 'Pending Approval') {
      setShowApproveBtn(true);
    }
    setShowDetails(true);
  }

  const [tagEmailId, setTagEmailId] = useState("");
  const [tagText, setTagText] = useState("");
  const [tagType, setTagType] = useState("");
  const [showEditTagsModal, setShowEditTagsModal] = useState(false);
  const handleCloseEditTagsModal = () => setShowEditTagsModal(false);
  
  const handleShowEditTagsModal = (emailid, tagtext, tagtype) => {
    console.log(emailid, tagtext);
    setTagEmailId(emailid);
    setTagText(tagtext);
    setTagType(tagtype);
    setShowEditTagsModal(true);
  }

  const [pendingEntitiesData, setPendingEntitiesData] = useState([]);
  const [showPendingEntities, setShowPendingEntities] = useState(false);
  const handleClosePendingEntities = () => setShowPendingEntities(false);
  
  const handleShowPendingEntities = (appid) => {
    console.log(appid);

    //get_application_pending_entities
    axios.post(serverConfig.api_base_url + "get_application_pending_entities",{token:mToken, applicationid: appid})
    .then(response=>{
      console.log('get_application_pending_entities response', response);
      if(response.data.status === 200){
        let items = JSON.parse(response.data.items);
        if (items !== null && items !== undefined){
          console.log("get_application_pending_entities response items:", items);
          setPendingEntitiesData(items);
          setShowPendingEntities(true);
        } else {
          setPendingEntitiesData([]);
        }
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.status === 301){
        getToken();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [tpDocsData, setTPDocsData] = useState([]);
  const [showTPDocsModal, setShowTPDocsModal] = useState(false);
  const handleCloseTPDocsModal = () => setShowTPDocsModal(false);
  
  const handleShowTPDocs = (appid) => {
    console.log(appid);
    setIsLoading(true);

    //get_application_final_docs
    axios.post(serverConfig.api_base_url + "get_application_final_docs",{token:mToken, applicationid: appid})
    .then(response=>{
      console.log('get_application_final_docs response', response);
      if(response.data.status === 200){
        let items = JSON.parse(response.data.items);
        if (items !== null && items !== undefined){
          console.log("get_application_final_docs response items:", items);
          setTPDocsData(items);
          setShowTPDocsModal(true);
          setIsLoading(false);
        }
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.status === 301){
        getToken();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [appDataGrid, setAppDataGrid] = useState([]);
  const [appDataGridRender, setAppDataGridRender] = useState([]);

  const handleApprove = (emailid) => {
    console.log(emailid);
    axios.post(serverConfig.api_base_url + "user_create_customer_access",{token:mToken, email_id:emailid})
    .then(response=>{
      console.log('user_create_customer_access response', response);
      if(response.data.status === 200){
        refreshAppList();
        getAppCount();
        setTimeout(() => {
          handleCloseDetails();
        }, 100);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.status === 501){
        alert(response.data.message);
      } else if(response.data.status === 301){
        getToken();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleUserAllowEditAfterSubmit = (emailid, event) => {
    console.log(emailid);
    let editAfterSubmit;
    if(event.target.checked){
      editAfterSubmit = 1;
    } else {
      editAfterSubmit = 0;
    }
    console.log({token:mToken, email_id:emailid, edit_after_submit:editAfterSubmit});
    axios.post(serverConfig.api_base_url + "user_allow_edit_after_submit",{token:mToken, email_id:emailid, edit_after_submit:editAfterSubmit})
    .then(response=>{
      console.log('user_allow_edit_after_submit response', response);
      if(response.data.status === 200){
        refreshAppList();
        getAppCount();
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.status === 501){
        alert(response.data.message);
      } else if(response.data.status === 301){
        getToken();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleArchiveUser = (appid, event) => {
    console.log(appid);
    var archiveUser;
    if(event.target.checked){
      archiveUser = 1;
    } else {
      archiveUser = 0;
    }

    console.log({token:mToken, applicationid: appid, is_archived:archiveUser});
    axios.post(serverConfig.api_base_url + "user_archive",{token:mToken, applicationid: appid, is_archived:archiveUser})
    .then(response=>{
      console.log('user_archive response', response);
      if(response.data.status === 200){
        refreshAppList();
        getAppCount();
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.status === 501){
        alert(response.data.message);
      } else if(response.data.status === 301){
        getToken();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleSaveTag = (e) => {
    e.preventDefault();
    axios.post(serverConfig.api_base_url + "set_access_request_tags",{token:mToken, email_id:tagEmailId, tags:tagText, tag_type: tagType})
    .then(response=>{
      console.log('set_access_request_tags response', response);
      if(response.data.status === 200){
        refreshAppList();
        getAppCount();
        handleCloseEditTagsModal();
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.status === 501){
        alert(response.data.message);
      } else if(response.data.status === 301){
        getToken();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log('props', show);
    console.log(mToken, sessionStorage.getItem('mToken'));
    
    if(mToken !== null && mToken !== undefined){
      console.log('mToken', mToken, show);
      //get_access_request_list
      axios.post(serverConfig.api_base_url + "get_access_request_list",{filter_check:show, token:mToken})
      .then(response=>{
        console.log('get_access_request_list response', response);
        if(response.data.status === 200){
          let items = JSON.parse(response.data.items);
          if (items !== null || items !== undefined){
            console.log("get_access_request_list response items:", items);
            setAppDataGrid(items);
            setAppDataGrid((stateAppDataGrid) => {
              let appDataArray = JSON.parse(JSON.stringify(stateAppDataGrid)); // deep copy?
              let appData = [];
              console.log(appDataArray);
              if(appDataArray){
                console.log(appDataArray.sort((a, b) => a.application_id - b.application_id));
              }
              //appDataArray.sort((a, b) => b.last_updated.localeCompare(a.last_updated)).map((item, index) => {
              appDataArray && appDataArray.reverse().map((item, index) => {
                let id_no = item.application_id;
                let tag1Text = item.tag1;
                let tag2Text = item.tag2;

                if(item.status == 'Account Requested'){
                  item.actions = [<>
                    <div className="d-flex align-items-center"><button onClick={() => handleApprove(item.email_id)} className='btn-icon approve-icon'><span></span></button><small className='ml-2'>Provision account</small></div>
                    {item.additional_info && <div className="d-flex align-items-center mt-2"><button onClick={() => handleShowDetails(item)} className='btn-icon'><i className="fa fa-eye" aria-hidden="true"></i></button><small className='ml-2'>View estimator data</small></div>}
                    <div className="d-flex align-items-center mt-2">
                      <label className="checkbox-toggle">
                        <input type="checkbox" checked={item.is_archived === 1 ? true : false} onChange={(e) => handleArchiveUser(id_no, e)} />
                        <span></span>
                      </label>
                      <small className='ml-2'>{item.is_archived ? 'Unarchive User' : 'Archive User'}</small>
                    </div>
                  </>];
                } else if(item.status.includes("Customer Update in Progress")){
                  item.actions = [<>
                    <div className="d-flex align-items-center"><button onClick={() => handleShowPendingEntities(id_no)} className='btn-icon'><i className="fa fa-eye" aria-hidden="true"></i></button><small className='ml-2'>View progress</small></div>
                    {item.additional_info && <div className="d-flex align-items-center"><button onClick={() => handleShowDetails(item)} className='btn-icon'><i className="fa fa-eye" aria-hidden="true"></i></button><small className='ml-2'>View estimator data</small></div>}
                    <div className="d-flex align-items-center mt-2">
                      <label className="checkbox-toggle">
                        <input type="checkbox" checked={item.is_archived === 1 ? true : false} onChange={(e) => handleArchiveUser(id_no, e)} />
                        <span></span>
                      </label>
                      <small className='ml-2'>{item.is_archived ? 'Unarchive User' : 'Archive User'}</small>
                    </div>
                  </>];
                } else if(item.status == 'Account Provisioned'){
                  item.actions = [<>
                    {item.additional_info && <div className="d-flex align-items-center"><button onClick={() => handleShowDetails(item)} className='btn-icon'><i className="fa fa-eye" aria-hidden="true"></i></button><small className='ml-2'>View estimator data</small></div>}
                    <div className="d-flex align-items-center mt-2">
                      <label className="checkbox-toggle">
                        <input type="checkbox" checked={item.is_archived === 1 ? true : false} onChange={(e) => handleArchiveUser(id_no, e)} />
                        <span></span>
                      </label>
                      <small className='ml-2'>{item.is_archived ? 'Unarchive User' : 'Archive User'}</small>
                    </div>
                  </>];
                } else if(item.status == 'Application Submitted'){
                  item.actions = [<>
                    <div className="d-flex align-items-center">
                      <label className="checkbox-toggle">
                        <input type="checkbox" checked={item.edit_after_submit} onChange={(e) => handleUserAllowEditAfterSubmit(item.email_id, e)} />
                        <span></span>
                      </label>
                      <small className='ml-2'>{item.edit_after_submit ? 'Revoke edit after submit' : 'Allow edit after submit'}</small>
                    </div>
                    {item.additional_info && <div className="d-flex align-items-center mt-2"><button onClick={() => handleShowDetails(item)} className='btn-icon'><i className="fa fa-eye" aria-hidden="true"></i></button><small className='ml-2'>View estimator data</small></div>}
                    <div className="d-flex align-items-center mt-2">
                      <label className="checkbox-toggle">
                        <input type="checkbox" checked={item.is_archived === 1 ? true : false} onChange={(e) => handleArchiveUser(id_no, e)} />
                        <span></span>
                      </label>
                      <small className='ml-2'>{item.is_archived ? 'Unarchive User' : 'Archive User'}</small>
                    </div>
                  </>];
                } else if(item.status == 'Completed'){
                  item.actions = [<>
                    {item.additional_info && <div className="d-flex align-items-center"><button onClick={() => handleShowDetails(item)} className='btn-icon'><i className="fa fa-eye" aria-hidden="true"></i></button><small className='ml-2'>View estimator data</small></div>}
                    <div className="d-flex align-items-center"><button onClick={() => handleShowTPDocs(id_no)} className='btn-icon'><i className="fa fa-eye" aria-hidden="true"></i></button><small className='ml-2'>View Tax preparer documents</small></div>
                    <div className="d-flex align-items-center mt-2">
                      <label className="checkbox-toggle">
                        <input type="checkbox" checked={item.is_archived === 1 ? true : false} onChange={(e) => handleArchiveUser(id_no, e)} />
                        <span></span>
                      </label>
                      <small className='ml-2'>{item.is_archived ? 'Unarchive User' : 'Archive User'}</small>
                    </div>
                  </>];
                } else {
                  item.actions = [<>
                    {item.additional_info && <div className="d-flex align-items-center"><button onClick={() => handleShowDetails(item)} className='btn-icon'><i className="fa fa-eye" aria-hidden="true"></i></button><small className='ml-2'>View estimator data</small></div>}
                    <div className="d-flex align-items-center mt-2">
                      <label className="checkbox-toggle">
                        <input type="checkbox" checked={item.is_archived === 1 ? true : false} onChange={(e) => handleArchiveUser(id_no, e)} />
                        <span></span>
                      </label>
                      <small className='ml-2'>{item.is_archived ? 'Unarchive User' : 'Archive User'}</small>
                    </div>
                  </>];
                }

                item.archive = ((new Date().getTime() - new Date(item.last_updated.substring(0,10)).getTime()) / (1000 * 3600 * 24)) > 7 && item.status !== "Completed" &&<i title="Not updated in past 7 days." className="ml-2 fas fa-exclamation-triangle txt-red"></i>;

                item.tag1 = <>{tag1Text} <button className='btn-icon ml-2' onClick={() => handleShowEditTagsModal(item.email_id, tag1Text, 1)}><i className="fa fa-edit"></i></button></>;

                item.tag2 = <>{tag2Text} <button className='btn-icon ml-2' onClick={() => handleShowEditTagsModal(item.email_id, tag2Text, 2)}><i className="fa fa-edit"></i></button></>;

                appData.push(item);
              });
              console.log(appData);
              setAppDataGridRender(appData);
              return stateAppDataGrid;
            });
          }
        } else if(response.data.status >= 400 && response.data.status <= 499){
          alert(response.data.message);
        } else if(response.data.status === 301){
          getToken();
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [show, isUserPrivileged, mToken]);

  const data = {
    columns: [
      {
        label: 'ID No.',
        field: 'application_id',
        //sort: 'disabled',
        width: 250
      },
      {
        label: 'Contact Person',
        field: 'contact_name',
        //sort: 'disabled',
        width: 270
      },
      {
        label: 'Created',
        field: 'created_at',
        //sort: 'disabled',
        width: 250
      },
      {
        label: '',
        field: 'archive',
        sort: 'disabled',
        width: 250
      },
      {
        label: 'Last Updated',
        field: 'last_updated',
        //sort: 'disabled',
        width: 250
      },
      {
        label: 'Contact Number',
        field: 'mobile_number',
        //sort: 'disabled',
        width: 200
      },
      {
        label: 'Email ID',
        field: 'email_id',
        //sort: 'disabled',
        width: 200
      },
      {
        label: 'Status',
        field: 'status',
        //sort: 'disabled',
        width: 200
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
        width: 250
      },
      {
        label: 'Source',
        field: 'tag1',
        sort: 'disabled',
        width: 250
      },
      {
        label: 'Company Name',
        field: 'tag2',
        sort: 'disabled',
        width: 250
      }
    ],

    rows: appDataGridRender,
  };

  const[file, setFile] = useState('');
  const [showFileModal, setShowFileModal] = useState(false);
  const showFile = (entityname, filedata) => {
    console.log(entityname);
    setIsLoading(true);
    setFile(filedata);
    setShowFileModal(true);
    setIsLoading(false);
  }
  const downloadFile = (entityname, filename, filedata) => {
    console.log(entityname);
    setIsLoading(true);
    alert('The File will now be downloaded into the folder that saves all your "Downloads".');
    FileSaver.saveAs(filedata, filename);
    setIsLoading(false);
  }

  return (
    <>
      {isLoading && <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>}
      <MDBDataTable striped borderless data={data} entries={10} entriesOptions={[ 10, 20, 30 ]} searchLabel="Type here"/>
      <Modal show={showDetails} onHide={handleCloseDetails} backdrop="static" keyboard={false} centered className='modal-medium modal-estimator'>
        <Modal.Header closeButton>
          <Modal.Title>Additonal Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <ul>
                <li><b>Contact Name :</b> <span className='txt-gray'>{accessRequestData.contact_name}</span></li>
                <li><b>Email ID :</b> <span className='txt-gray'>{accessRequestData.email_id}</span></li>
                <li><b>Phone No. :</b> <span className='txt-gray'>{accessRequestData.mobile_number}</span></li>
              </ul>
            </Col>
            <Col>
              <ul>
                <li><b>Company Name :</b> <span className='txt-gray'>{accessRequestData.additional_info && accessRequestData.additional_info.company_name}</span></li>
                <li><b>PPP Loan Number :</b> <span className='txt-gray'>{accessRequestData.additional_info && accessRequestData.additional_info.ppp_loan_number}</span></li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col><b>VKFE Min :</b> <span className='txt-gray'>{accessRequestData.additional_info && accessRequestData.additional_info.min && <>$ {accessRequestData.additional_info.min}</>}</span></Col>
            <Col><b>VKFE Max :</b> <span className='txt-gray'>{accessRequestData.additional_info && accessRequestData.additional_info.max && <>$ {accessRequestData.additional_info.max}</>}</span></Col>
          </Row>
          <Table bordered striped>
            <thead>
              <tr>
                <th width="10%">Year</th>
                <th width="18%">Q1 (Jan - Mar)</th>
                <th width="18%">Q2 (Apr - Jun)</th>
                <th width="18%">Q3 (Jul - Sep)</th>
                <th width="18%">Q4 (Oct - Dec)</th>
                {/* <th width="18%">No. of Employees</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>2019</th>
                <td>{accessRequestData.additional_info && accessRequestData.additional_info.GrossReceipt2019Q1 && <>$ {accessRequestData.additional_info.GrossReceipt2019Q1}</>}</td>
                <td>{accessRequestData.additional_info && accessRequestData.additional_info.GrossReceipt2019Q2 && <>$ {accessRequestData.additional_info.GrossReceipt2019Q2}</>}</td>
                <td>{accessRequestData.additional_info && accessRequestData.additional_info.GrossReceipt2019Q3 && <>$ {accessRequestData.additional_info.GrossReceipt2019Q3}</>}</td>
                <td>{accessRequestData.additional_info && accessRequestData.additional_info.GrossReceipt2019Q4 && <>$ {accessRequestData.additional_info.GrossReceipt2019Q4}</>}</td>
                {/* <td>{accessRequestData.additional_info && accessRequestData.additional_info.Employees2019}</td> */}
              </tr>
              <tr>
                <th>2020</th>
                <td>{accessRequestData.additional_info && accessRequestData.additional_info.GrossReceipt2020Q1 && <>$ {accessRequestData.additional_info.GrossReceipt2020Q1}</>}</td>
                <td>{accessRequestData.additional_info && accessRequestData.additional_info.GrossReceipt2020Q2 && <>$ {accessRequestData.additional_info.GrossReceipt2020Q2}</>}</td>
                <td>{accessRequestData.additional_info && accessRequestData.additional_info.GrossReceipt2020Q3 && <>$ {accessRequestData.additional_info.GrossReceipt2020Q3}</>}</td>
                <td>{accessRequestData.additional_info && accessRequestData.additional_info.GrossReceipt2020Q4 && <>$ {accessRequestData.additional_info.GrossReceipt2020Q4}</>}</td>
                {/* <td>{accessRequestData.additional_info && accessRequestData.additional_info.Employees2020}</td> */}
              </tr>
              <tr>
                <th>2021</th>
                <td>{accessRequestData.additional_info && accessRequestData.additional_info.GrossReceipt2021Q1 && <>$ {accessRequestData.additional_info.GrossReceipt2021Q1}</>}</td>
                <td>{accessRequestData.additional_info && accessRequestData.additional_info.GrossReceipt2021Q2 && <>$ {accessRequestData.additional_info.GrossReceipt2021Q2}</>}</td>
                <td>{accessRequestData.additional_info && accessRequestData.additional_info.GrossReceipt2021Q3 && <>$ {accessRequestData.additional_info.GrossReceipt2021Q3}</>}</td>
                <td>{accessRequestData.additional_info && accessRequestData.additional_info.GrossReceipt2021Q4 && <>$ {accessRequestData.additional_info.GrossReceipt2021Q4}</>}</td>
                {/* <td>{accessRequestData.additional_info && accessRequestData.additional_info.Employees2021}</td> */}
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="transparent" onClick={handleCloseDetails}>Close</Button>
          {showApproveBtn && <Button variant="primary" onClick={() => handleApprove(accessRequestData.email_id)} className='ml-3'><img src={require('./../assets/images/approve-icon.png')} alt="Approve Icon" className='mr-2' />Approve</Button>}
        </Modal.Footer>
      </Modal>
      
      <Modal show={showPendingEntities} onHide={handleClosePendingEntities} backdrop="static" keyboard={false} centered className='modal-medium'>
        <Modal.Header closeButton>
          <Modal.Title>Pending</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {pendingEntitiesData && pendingEntitiesData.map((item, index) => {
              return(<>
                <Table bordered striped>
                  <tbody>
                    <tr>
                      <th width="100">{item.group}</th>
                      <td>
                        {item.entities && item.entities.map((itemEntity, indexEntity) => {
                          return(
                            <span key={indexEntity}>{itemEntity}{indexEntity+1 !== item.entities.length && <>, </>}</span>
                          )
                        })}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>)
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="transparent" onClick={handleClosePendingEntities}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTPDocsModal} onHide={handleCloseTPDocsModal} backdrop="static" keyboard={false} centered className='modal-medium'>
        <Modal.Header closeButton>
          <Modal.Title>Tax Preparer Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{overflowX:"hidden"}}>
            {tpDocsData && <>
              <section>
              {tpDocsData.map((item, index) => {
                if(Object.keys(item)[1]==='TaxPreparerComments'){
                  return(item.TaxPreparerComments && <div key={index}>
                    <h3 className="mb-3"><b>Tax Preparer Comments</b></h3>
                    <div>
                      <div className="mb-4 d-inline-flex">
                        <div className="text-center"><div className='txt-green'>&#x2714;</div>
                          <span className="txt-overflow">{item[Object.keys(item)[1]] }</span>
                        </div>
                      </div>
                    </div>
                  </div>)}
                })}
              </section>
              {/* <section>
                <h3 className='mb-2'>Statement of Fact</h3>
                <Table bordered>
                  <thead>
                    <tr>
                      <th colSpan={2} className='text-center'>Sent to Customer</th>
                      <th colSpan={2} className='text-center'>Accepted by Customer</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Status</th>
                      <th>Timestamp</th>
                      <th>Status</th>
                      <th>Timestamp</th>
                    </tr>
                    <tr>
                      <td>
                      {tpDocsData.map((item, index) => {
                        if(Object.keys(item)[1]==='StatementOfFactSentToCustomer'){
                          return(<div key={index}>
                            {item.StatementOfFactSentToCustomer === 'true' ? 'Yes' : 'No'}
                          </div>)
                        }
                      })}
                      </td>
                      <td>{tpDocsData.map((item, index) => {
                        if(Object.keys(item)[1]==='StatementOfFactSentToCustomer'){
                          return(<div key={index}>
                            {item.updated_at}
                          </div>)
                        }
                      })}</td>
                      <td>{tpDocsData.map((item, index) => {
                        if(Object.keys(item)[1]==='StatementOfFactSignedByCustomer' && item.StatementOfFactSignedByCustomer !==null){
                          return(<div key={index}>
                            {item.StatementOfFactSignedByCustomer && (JSON.parse(item.StatementOfFactSignedByCustomer).Signed = 'true' ? 'Yes' : 'No')}
                          </div>)
                        } else if(Object.keys(item)[1]==='SignedStatementOfFactFile' && item.SignedStatementOfFactFile !==null){
                          return(<div key={index}>
                            {item.SignedStatementOfFactFile ? 'Yes' : 'No'}
                          </div>)
                        }
                      })}</td>
                      <td>{tpDocsData.map((item, index) => {
                        if(Object.keys(item)[1]==='StatementOfFactSignedByCustomer' && item.StatementOfFactSignedByCustomer !==null){
                          return(<div key={index}>
                            {item.StatementOfFactSignedByCustomer && JSON.parse(item.StatementOfFactSignedByCustomer).SignerDate}
                          </div>)
                        } else if(Object.keys(item)[1]==='SignedStatementOfFactFile' && item.SignedStatementOfFactFile !==null){
                          return(<div key={index}>
                            {item.SignedStatementOfFactFile && item.updated_at}
                          </div>)
                        }
                      })}</td>
                    </tr>
                  </tbody>
                </Table>
              </section> */}
              <section>
                <h3 className='mb-2'>Final Form</h3>
                <div className='w-50'>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th colSpan={2} className='text-center'>Sent to Customer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Status</th>
                        <th>Timestamp</th>
                      </tr>
                      <tr>
                        <td>
                        {tpDocsData.map((item, index) => {
                          if(Object.keys(item)[1]==='FinalForm941xSentToCustomer'){
                            return(<div key={index}>
                              {item.FinalForm941xSentToCustomer === 'true' ? 'Yes' : 'No'}
                            </div>)
                          }
                        })}
                        </td>
                        <td>{tpDocsData.map((item, index) => {
                          if(Object.keys(item)[1]==='FinalForm941xSentToCustomer'){
                            return(<div key={index}>
                              {item.updated_at}
                            </div>)
                          }
                        })}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </section>
              <section className='mt-0'>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className='text-center'>Doc 01</th>
                      <th className='text-center'>Doc 02</th>
                      <th className='text-center'>Doc 03</th>
                      <th className='text-center'>Doc 04</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    {tpDocsData.map((item, index) => {
                      if(Object.keys(item)[1]==='FinalFormDoc1'){
                        return(<>
                        <td>
                          {item[Object.keys(item)[1]] && <div className="text-center"><div className='txt-green'>&#x2714;</div>
                            <span className="txt-overflow">{item[Object.keys(item)[1]] }</span>
                          </div>}
                        </td>
                        </>)}
                      if(Object.keys(item)[1]==='FinalFormDoc2'){
                        return(<>
                        <td>
                          {item[Object.keys(item)[1]] && <div className="text-center"><div className='txt-green'>&#x2714;</div>
                            <span className="txt-overflow">{item[Object.keys(item)[1]] }</span>
                          </div>}
                        </td>
                        </>)}
                      if(Object.keys(item)[1]==='FinalFormDoc3'){
                        return(<>
                        <td>
                          {item[Object.keys(item)[1]] && <div className="text-center"><div className='txt-green'>&#x2714;</div>
                            <span className="txt-overflow">{item[Object.keys(item)[1]] }</span>
                          </div>}
                        </td>
                        </>)}
                      if(Object.keys(item)[1]==='FinalFormDoc4'){
                        return(<>
                        <td>
                          {item[Object.keys(item)[1]] && <div className="text-center"><div className='txt-green'>&#x2714;</div>
                            <span className="txt-overflow">{item[Object.keys(item)[1]] }</span>
                          </div>}
                        </td>
                        </>)}
                      })
                    }
                    </tr>
                  </tbody>
                </Table>
              </section>
            </>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="transparent" onClick={handleCloseTPDocsModal}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal className='show-picture' show={showFileModal} onHide={() => {setShowFileModal(false); setFile('')}} backdrop="static"keyboard={false} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <embed src={file} width='100%' height="600" />
        </Modal.Body>
      </Modal>

      <Modal show={showEditTagsModal} onHide={handleCloseEditTagsModal} backdrop="static" keyboard={false} centered>
        <form onSubmit={handleSaveTag}>
          <Modal.Header closeButton>
            <Modal.Title>Tag {tagType}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input type="text" defaultValue={tagText} onChange={(e) => setTagText(e.target.value)} className="form-control" required />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={handleCloseEditTagsModal}>Cancel</Button>
            <Button variant="primary" type="submit" className='ml-3'>Save</Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default AppDataGrid;
