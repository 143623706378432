import React, {useState, useEffect} from 'react';
import { Button, Form, Modal} from 'react-bootstrap';
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import UserService from '../services/UserService';
import { useNavigate } from "react-router-dom";

const serverConfig = require("../config/server.js");

const VideoList = () => {

  const [token, setToken] = useState(null);
  const [appDataGridRender, setAppDataGridRender] = useState();
  const [actions, setActions] = useState(false);

  const [userPrivileged, setUserPrivileged] = useState(false);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    //window.spartezSupportChat.sso.logout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "get_user_token",{jwt:jwt})
    .then(response=>{
      console.log('get_user_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined){
      /* check_user_privilege - naics_code_admin */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:token, privilege:'naics_code_admin'})
      .then(response=>{
        console.log("naics_code_admin privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setUserPrivileged(false);
            navigate("/");
          } else if(response.data.is_privileged === 1){
            setUserPrivileged(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token]);

  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    console.log('userPrivileged', userPrivileged);
    
    if(token !== null && token !== undefined){
      if(userPrivileged) {
        console.log('token', token);
        /* get_vkfe_video_list */
        axios.post(serverConfig.api_base_url + "get_vkfe_video_list",{token:token})
        .then(response=>{
          console.log("get_vkfe_video_list response:", response);
          setIsLoading(false);
          if(response.data.status === 200){
            let items = JSON.parse(response.data.items);
            console.log(items);
            if (items !== null || items !== undefined){
              let appData = [];
              items.map((item, index) => {
                return (<>
                  {item.actions = (<div className='text-center'>
                    <button className='btn-icon' title='Edit Video' onClick={() => handleShowEditVideoModal(item.video_desc, JSON.parse(response.data.items))}><i className="fas fa-edit txt-gray"></i></button>
                  </div>)}

                  {appData.push(item)}
                </>);
              });
              //console.log(appData);
              setAppDataGridRender(appData);
            }
          } else if(response.data.status >= 400 && response.data.status <= 499){
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      }
    }
  }, [token, userPrivileged, actions]);

  const data = {
    columns: [
      { label: 'Name', field: 'video_desc', sort: false, width: 250 },
      { label: 'URL', field: 'video_url', sort: true, width: 500 },
      { label: 'Actions', field: 'actions', sort: 'disabled' },
    ],
    rows: appDataGridRender,
  };

  //Add Video
  const [videoInputs, setVideoInputs] = useState({});
  const [videoErrorTxt, setVideoErrorTxt] = useState(null);

  const [showAddVideoModal, setShowAddVideoModal] = useState(false);
  const handleCloseAddVideoModal = () => {
    setVideoInputs({});
    setVideoErrorTxt(null);
    setShowAddVideoModal(false);
  }
  const handleShowAddVideoModal = () => setShowAddVideoModal(true);

  const handleChange = (event) => {
    setVideoErrorTxt(null);
    const name = event.target.name;
    const value = event.target.value;
    setVideoInputs(values => ({...values, [name]: value}));
  }

  const handleAddVideo = (event) => {
    event.preventDefault();
    console.log(videoInputs);
    //set_vkfe_video
    axios.post(serverConfig.api_base_url + "set_vkfe_video",{token:token, vkfe_video_url:videoInputs.video_url, vkfe_video_desc: videoInputs.video_desc})
    .then(response=>{
      console.log('set_vkfe_video response', response);
      if(response.data.status === 200){
        console.log(response.data);
        handleCloseAddVideoModal();
        setActions(!actions);//refresh records table
      } else if(response.data.status === 501){
        setVideoErrorTxt(response.data.message);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Edit Video
  const [editVideoInputs, setEditVideoInputs] = useState({});
  const [editVideoErrorTxt, setEditVideoErrorTxt] = useState(null);

  const [showEditVideoModal, setShowEditVideoModal] = useState(false);
  const handleCloseEditVideoModal = () => {
    setEditVideoErrorTxt(null);
    setEditVideoInputs({});
    setShowEditVideoModal(false);
  }
  const handleShowEditVideoModal = (video_desc, videoList) => {
    console.log(video_desc, videoList);
    let videoData = videoList.filter(item => item.video_desc === video_desc);
    console.log(videoData, videoData[0]);
    setEditVideoInputs(videoData[0]);
    setShowEditVideoModal(true);
  }

  const handleChangeVideoInputs = (event) => {
    setEditVideoErrorTxt(null);
    const name = event.target.name;
    const value = event.target.value;
    setEditVideoInputs(values => ({...values, [name]: value}))
  }

  const handleEditVideo = (event) => {
    event.preventDefault();
    console.log(editVideoInputs, videoInputs);
    //set_vkfe_video
    axios.post(serverConfig.api_base_url + "set_vkfe_video",{token:token, vkfe_video_url:editVideoInputs.video_url, vkfe_video_desc: editVideoInputs.video_desc})
    .then(response=>{
      console.log('set_vkfe_video response', response);
      if(response.data.status === 200){
        console.log(response.data);
        handleCloseEditVideoModal();
        setActions(!actions);//refresh records table
      } else if(response.data.status === 501){
        setEditVideoErrorTxt(response.data.message);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <>
      {isLoading && <div className="loading"><img src={require('./../assets/images/spinner.gif')} height="70" alt="Spinner" /></div>}
      <div className='page-heading'>
        <h1>Video List</h1>
        <Button variant="transparent" className='btn-txt-black' onClick={handleShowAddVideoModal}>Add Video</Button>
        <Modal show={showAddVideoModal} onHide={handleCloseAddVideoModal} backdrop="static"keyboard={false} centered className='modal-small'>
          <Form onSubmit={handleAddVideo}>
            <Modal.Header closeButton>
              <Modal.Title>Add Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Video URL*" name="video_url" defaultValue={videoInputs.video_url || ""} onChange={handleChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Description*" name="video_desc" defaultValue={videoInputs.video_desc || ""} onChange={handleChange} required />
              </Form.Group>
              <p className='txt-red mb-0'>{videoErrorTxt ? videoErrorTxt : <>&nbsp;</>}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="transparent" onClick={handleCloseAddVideoModal}>Cancel</Button>
              <Button variant="primary" type="submit" className='ml-3'>Add</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
      <section>
        <div className='datatable table-layout-auto'>
          <MDBDataTable striped borderless data={data} entries={11} entriesOptions={[ 10, 20, 30 ]} searchLabel="Type here" />
        </div>
      </section>
      <Modal show={showEditVideoModal} onHide={handleCloseEditVideoModal} backdrop="static"keyboard={false} centered className='modal-small'>
        <Form onSubmit={handleEditVideo}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Video URL" name="video_url" defaultValue={editVideoInputs.video_url} onChange={handleChangeVideoInputs} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Description" name="video_desc" value={editVideoInputs.video_desc} readOnly />
            </Form.Group>
            <p className='txt-red mb-0'>{editVideoErrorTxt ? editVideoErrorTxt : <>&nbsp;</>}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={handleCloseEditVideoModal}>Cancel</Button>
            <Button variant="primary" type="submit" className='ml-3'>Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default VideoList;