import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';

const PrivacyPolicy = () => {

  return (<>
    <Header />
    <main>
      <Container fluid>
        <h1>Privacy Policy</h1>
        <p>Effective Date: March 6, 2023</p>
        <p>This privacy notice describes how Amberoon Inc. d/b/a VentoKredit  ("VentoKredit" or "we") uses and protects any information that we may collect about you when you visit our website (www.ventokredit.com) and other websites operated by us (collectively the “Sites”), when you use our Services, or communicate with us.</p>
        <p>We offer to employers (“Customers”) access to our SaaS platform for the processing, analysis, and preparation of tax reports for the calculation of available Employee Retention Tax Credit  (the “Services”).    Please rest assured that the protection and security of your Personal Information are very important to us,.  The following sections explain our collection, use, disclosure, retention, and protection of your personal data.</p>
        <ol>
          <li>
            <b><u>Collection, Processing and Use of Personal Information.</u></b>
            <p>For purposes of this Privacy Policy, "Personal Information" means any information from or about an individual that either reasonably identifies that individual or that makes that individual reasonably identifiable when it is combined with other information from that individual.</p>
            <ol type="a">
              <li>
                <b><u>What Data is Automatically Collected</u></b>
                <p>Most of the data we collect from the Sites and the Services is technical in nature and is collected and processed automatically through cookies and similar widgets used on the Sites.  The data we may collect by such automated means may include:</p>
                <ul>
                  <li>The Uniform Resource Locator (“URL”) of the site you visited before coming to our Sites, the URL of the site you visit after leaving our Sites, the type of browser you are using and your Internet Protocol (“IP”) address;</li>
                  <li>Behavioural data or usage history relating to the Sites;</li>
                  <li>Browsing history; and</li>
                  <li>Information we collect on the use of the Sites via cookies.</li>
                </ul>
                <p>This automatically collected data may constitute Personal Information by itself, or in combination with other data.</p>
                <ol type="i">
                  <li>
                    <u>What Data is Automatically Collected</u>
                    <p>To make your visit to our Sites more attractive and facilitate the quality and use of certain features, we use "cookies".  Cookies are small files that are stored on your device and save specific settings and data for exchange with our system via your browser. Cookies do not contain personal information and cannot be traced to a specific individual.  We receive only anonymous  and aggregated information, such as which pages on our Sites have been viewed.  Please keep in mind that certain cookies are already set when you access our Sites. You can configure your browser so that you are notified about the setting of cookies and personally choose whether to accept them or not or exclude the acceptance of cookies in certain cases or generally.</p>
                    <p>Internet browsers allow you to control the storage of cookies on your computer. This is described in the Help menu of each browser and explains how to change your cookie settings.  However, deactivating cookies may render some functions of our Sites unusable.</p>
                    <p>We use Google Analytics and HubSpot Analytics for our Sites. By using cookies, these analytics applications collect and store data such as time of visit, pages visited, time spent on each page of the Sites, the Internet Protocol address, and the type of operating system used in the devices used to access the Sites. By using a browser plugin available at <a href="http://www.google.com/ads/preferences/plugin/" target="_blank">http://www.google.com/ads/preferences/plugin/</a> provided by Google, you can opt out of Google Analytics.</p>
                    <p>Similarly, you may learn how to opt out of HubSpot Analytics by <a href="https://knowledge.hubspot.com/account/hubspot-machine-learning-data#:~:text=If%20you%20want%20to%20exclude,in%20HubSpot's%20machine%20learning%20models.&text=Thanks%20for%20letting%20us%20know,would%20you%20describe%20this%20article%3F" target="_blank">clicking here</a>.</p>
                  </li>
                  <li>
                    <u>What types of Cookies are used?</u>
                    <ol type="i">
                      <li>Required cookies enable you to navigate the Sites and use its features, such as accessing secure areas of the Sites and using our Services. If you have registered with us, we use cookies containing encrypted information to allow us to uniquely identify you. These cookies allow us to uniquely identify you when you are logged into the Sites and to process your requests. There is no option to opt out of these cookies since they required cookies are essential to operate the Sites.</li>
                      <li>Performance cookies collect information about how you use the Sites, including which pages you frequently visit and any error messages from certain pages.  We only use the information collected from performance cookies to improve how the Sites perform.  To learn how to opt out of performance cookies using your browser settings, <a href="https://www.aboutcookies.org/" target="_blank">click here</a>. To learn how to manage privacy and storage settings for Flash cookies, <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html" target="_blank">click here</a>.</li>
                      <li>Functionality cookies allow the Sites to remember data that you have entered or choices you make (such as your username or region) so that we can provide more personalized features, and optimize your use of the Sites after logging in.   We may use local Flash cookies to recognize your preferences or display content based upon what you view on the Sites to personalize your visit. To learn how to opt out of functionality cookies using your browser settings, <a href="https://www.aboutcookies.org/" target="_blank">click here</a>. To learn how to manage privacy and storage settings for Flash cookies, <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html" target="_blank">click here</a>.</li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <b><u>What Data Will We Collect from Third Parties</u></b>
                <p>In connection with the Services, we may receive information from our business partners such as the Certified Public Accountants that will be performing the Accounting Services as described in your ERC SaaS Services Agreement  with us.  This data may include first name, last name, addresses, account numbers, social security numbers, employer identification numbers, email addresses, phone numbers, employer’s financial and tax filing information, and other employer details.</p>
              </li>
              <li>
                <b><u>Data That You Provide to VentoKredit</u></b>
                <p>We will collect data that you provide to us directly when you use our Services, or communicate with us.   The information you provide to us directly may include the following information that may, alone or in combination with other data, constitute Personal Information:</p>
                <ul>
                  <li>Information you provide via email or using the contact details listed on various parts of the Sites, including your name, company, and phone number, and any other information you provide to us;</li>
                  <li>As a Customer, we may also process certain Personal Information in the course of our business relation with such as your name, company, title, e-mail address, phone number, and address.  </li>
                  <li>During the course of performing the Services, we will also be collecting business information from the Customer, which may include the Customer’s social security numbers, employer identification numbers, employee payroll data, hire reports, prior tax filings,  the driver’s license for the principal owner of the Customer,  and other records as required to perform the Services.</li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <b><u>For What Purposes Do We Collect and Use Personal Information</u></b>
            <p>The data collected automatically in connection with the Services is used to for the following purposes:</p>
            <ul>
              <li>Respond to questions, requests,  comments or complaints made by you or otherwise communicate with you;</li>
              <li>Analytics and/or market research;</li>
              <li>Advertising;</li>
              <li>To track new visitors to the Sites;</li>
              <li>Troubleshooting and maintenance of the Sites;</li>
              <li>Obtaining geographic information of our visitors;</li>
              <li>Improving our understanding of how Customers interact with the Sites and the Services;</li>
              <li>Improving the Sites and the Services;</li>
              <li>To measure the overall effectiveness of our marketing and content;</li>
              <li>To help us recognize your browser as a previous visitor to the Sites; and</li>
              <li>Complying with applicable law, and cooperating with law enforcement activities.</li>
            </ul>
            <p>In addition to the above, we use the Personal Information for our legitimate purposes of protecting our legal rights and the data we process, in connection with legal claims, and for compliance, regulatory, and investigative purposes. This may include sharing the Personal Information with third parties, such as governmental authorities or law enforcement officials subject to applicable law.</p>
          </li>
          <li>
            <b><u>Sharing of Personal Information.</u></b>
            <p>We may disclose Personal Information with the following third parties:</p>
            <ul>
              <li>To the Certified Public Accountants that will be performing the Accounting Services as described in your ERC SaaS Services Agreement  with us;</li>
              <li>Service providers, such as cloud data storage service providers, marketing service providers, and communications service providers. Such service providers are only allowed to use Personal Information disclosed to them for the purpose of providing us the services and for no other purposes;</li>
              <li>Third party financial institutions (such as the Lockbox Bank identified in your ERC SaaS Services Agreement, and payment processing companies like Paypal and Stripe) with whom we partner, for them to provide joint content and services, such as facilitating the processing of payments, to prevent, detect, mitigate, and investigate potentially illegal acts, fraud and/or security breaches;</li>
              <li>Law enforcement or governmental agencies (such as the IRS), to comply with our legal requirements, respond to claims, or protect anyone's rights, property or safety; and</li>
              <li>Our subsidiaries and affiliates; or a subsequent owner, co-owner or operator of the Sites and/or the Services and their advisors in connection with a corporate merger, consolidation, restructuring, the sale of substantially all of our stock and/or assets, or in connection with bankruptcy proceedings, or other corporate reorganization, in accordance with this Privacy Notice.</li>
            </ul>
            <p>The use by the mentioned third parties of the disclosed data is strictly limited to these mentioned purposes. We will never disclose your personal data to a third party for marketing purposes without your consent.</p>
          </li>
          <li>
            <b><u>Retention of Data and Data Security.</u></b>
            <ol type="a">
              <li>We retain Personal Information about you only for as long as needed for the purposes for which it was collected and to comply with applicable laws.   If you are a Customer, we will retain Personal Information about you until the Services are completed and subject to the data retention policies stated in our ERC SaaS Services Agreement.</li>
              <li>We maintain commercially reasonable data security measures against the risk of loss, misuse, unauthorized access, which includes end-to-end encryption for Personal Information in transit and encryption for Personal Information at rest.  All information that you provide to us is stored on our secure servers behind firewalls. We continually adapt our security measures to reflect technological developments.</li>
            </ol>
            <p>We also take internal protection measures. Our employees are obliged to maintain secrecy and comply with applicable data protection laws. In addition, employees and service providers are only granted access to personal data on a “need to know” basis.</p>
            <p>While we strive to do our best to protect your personal data, we cannot absolutely guarantee that unauthorized access to, or disclosure of, your personal data will never occur. We therefore cannot warrant that Personal Information about you will be protected against, loss, misuse, or alteration by such bad actors or criminals.</p>
          </li>
          <li>
            <b><u>Links To Other Websites.</u></b>
            <p>This privacy policy addresses only our use and handling of personal data we collect from you in connection with providing you our Services through our Sites.  The Sites may contain links to other websites of interest. However, once you have used these links to leave our Sites, we do not have any control over third party websites.</p>
            <p>If you disclose your information to a third party, or visit a third party website via a link in our Sites, their respective privacy policy will apply to any personal data that you provide to them or that they collect from you.  We cannot guarantee the privacy or security of your personal data once you provide it to a third party and we encourage you to consult the privacy policies and security policies of third parties before entering into a transaction and choosing to share your personal data.</p>
            <p>We cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this Privacy Notice. You should exercise caution and review the privacy notice applicable to the website in question.</p>
          </li>
          <li>
            <b><u>Children’s Privacy.</u></b>
            <p>The Sites and the Services are not intended for children under the age of 18.   As such, we do not intend to collect Personal Information from anyone we know to be under 18 years of age. If you are under the age of 18, you are not permitted to use the Sites.</p>
          </li>
          <li>
            <b><u>Do-Not-Track-Signals</u></b>
            <p>Our Sites do not respond to do-not-track signals. You may, however, disable certain tracking as discussed above (e.g., by disabling cookies) as set out above.</p>
          </li>
          <li>
            <b><u>Notice To California Residents Only.</u></b>
            <p>California Civil Code Section 1798.83 permits Website(s) users who are California residents to request certain information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. To make a request, please contact us: <a href="mailto:privacy@ventokredit.com">privacy@ventokredit.com</a></p>
            <p>Furthermore, California residents have the following rights with respect to Personal Information we may have collected about them:</p>
            <ol type="a">
              <li>You have the right to request that we disclose:
                <ol type="i">
                  <li>The categories of Personal Information we have collected about you;</li>
                  <li>The categories of Personal Information about you we have sold or disclosed for a business purpose;</li>
                  <li>The categories of sources from which we have collected Personal Information about you;</li>
                  <li>The business or commercial purposes for selling or collecting Personal Information about you;</li>
                  <li>The categories of Personal Information sold or shared about you, as well as the categories of third parties to whom the Personal Information was sold, by category of Personal Information for each party to whom Personal Information was sold; and</li>
                  <li>The specific pieces of Personal Information collected.</li>
                </ol>
                <p>You may submit a request to know via <a href="mailto:privacy@ventokredit.com">privacy@ventokredit.com</a>.  We will respond to you via e-mail or by mail. We are not required to respond to requests to know more than twice in a 12-month period.</p>
              </li>
              <li>You have the right to request that we delete any Personal Information about you that we have collected. Upon receiving a verified request to delete Personal Information, we will do so unless otherwise authorized by law. You may submit a request to delete Personal Information by contacting us below.</li>
              <li>You may designate an authorized agent to make requests on your behalf. You must provide an authorized agent written permission to submit a request on your behalf, and we may require that you verify your identity directly with us. Alternatively, an authorized agent that has been provided power of attorney pursuant to Probate Code sections 4000-4465 may submit a request on your behalf.</li>
              <li>
                <p><u>Methods for Submitting Consumer Requests</u>. You may submit a request to review and request to delete Personal Information by emailing us at <a href="mailto:privacy@ventokredit.com">privacy@ventokredit.com</a>.</p>
                <p>We may ask you for additional information to verify your identity after we receive your request.    Any additional information you provide will be used only to verify your identity and not for any other purpose.</p>
                <p>We will acknowledge the receipt of your request within ten (10) days of receipt. Subject to our ability to verify your identity, we will respond to your request within 45 days of receipt. In order to protect your privacy and the security of Personal Information about you, we verify your request by verifying your ownership of the email address used for the account. A verification link will be emailed to your email address for you to select and confirm your ownership of that email address, and then the data collection or deletion process can be initiated.</p>
              </li>
              <li>You have the right not to be discriminated against for the exercise of your California privacy rights described above.</li>
              <li><u>Sale of Personal Information.</u> We have not sold Personal Information collected in and through the Sites. Consequently, we do not have an opt-out functionality on the Sites.</li>
            </ol>
          </li>
          <li>
            <b><u>Changes To The Privacy Notice.</u></b>
            <p>This Privacy Notice may change from time to time, effective from the date mentioned in the updated version of the Privacy Notice. Please check the Sites periodically to review such changes in the Privacy Notice. We may email periodic reminders of our agreements and policies in the event of a change.</p>
          </li>
          <li>
            <b><u>Questions, complaints or requests?</u></b>
            <p>If you have any questions about this Privacy Notice, please contact us via the following:</p>
            <p><a href="mailto:privacy@ventokredit.com">privacy@ventokredit.com</a></p>
            <p>
              Amberoon Inc. d/b/a VentoKredit<br/>
              20660 Stevens Creek Boulevard, Suite 249<br/>
              Cupertino, CA, 95014
            </p>
          </li>
        </ol>
      </Container>
    </main>
  </>);
}

export default PrivacyPolicy;