import React, {useState, useEffect} from 'react';
import { Row, Col, Button, Form, Modal} from 'react-bootstrap';
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import UserService from '../services/UserService';
import { useNavigate } from "react-router-dom";

const serverConfig = require("../config/server.js");

const Users = () => {

  const [token, setToken] = useState(sessionStorage.getItem('token'));
  const [appDataGridRender, setAppDataGridRender] = useState();
  const [actions, setActions] = useState(false);

  const [userPrivileged, setUserPrivileged] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    //window.spartezSupportChat.sso.logout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "get_user_token",{jwt:jwt})
    .then(response=>{
      console.log('get_user_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined){
      /* check_user_privilege - read_user_list */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:token, privilege:'read_user_list'})
      .then(response=>{
        console.log("read_user_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setUserPrivileged(false);
            navigate("/customer");
          } else if(response.data.is_privileged === 1){
            setUserPrivileged(true);
          }
        } else if(response.data.status >= 400 && response.data.status <= 499){
          alert(response.data.message);
        } else if(response.data.status === 301){
          getToken();
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token]);

  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    console.log('userPrivileged', userPrivileged);
    
    //if(token !== null && token !== undefined && userPrivileged){
    if(userPrivileged) {
      if(token !== null && token !== undefined){
        console.log('token', token);
        /* get_user_list */
        axios.post(serverConfig.api_base_url + "get_user_list",{token:token})
        .then(response=>{
          console.log("get_user_list response:", response);
          if(response.data.status === 200){
            let items = JSON.parse(response.data.items);
            console.log(items);
            //setAppDataGridRender(items);
            if (items !== null || items !== undefined){
              let appData = [];
              items.reverse().map((item, index) => {
                let curRole = [];
                curRole = item.roles;
                //console.log(curRole);
                //console.log(Array.of(curRole));
                return (<>
                  {/* {item.roles = [<div><ul>{item.roles.map((i, k) => {
                    return (<li key={k}>{i}</li>)
                  })}</ul><button className='btn-icon ml-2' title='Edit user role'><i className="far fa-edit"></i></button></div>]} */}


                  {item.roles = [<div><ul> { curRole && curRole.map((itemRole, i) => <li key={i}>{ itemRole }</li>) }</ul><button className='btn-icon ml-2' title='Edit user role' onClick={() => handleRolesModal(item.name, item.email_id, curRole)}><i className="far fa-edit"></i></button></div>]}

                  {/* {item.roles = [<div><ul><li>{item.roles}</li></ul><button className='btn-icon ml-2' title='Edit user role' onClick={() => handleRolesModal(item.name, item.email_id, curRole)}><i className="far fa-edit"></i></button></div>]} */}
              
                  {item.active = item.active === 0 ? (<><button className='btn-icon mr-2' title='Enable user' onClick={() => handleActive(item.email_id, 1)}><i className="far fa-user txt-gray"></i></button> Disabled</>) : (<><button className='btn-icon mr-2' title='Disable user' onClick={() => handleActive(item.email_id, 0)}><i className="fas fa-user"></i></button> Enabled</>)}

                  {item.locked = item.locked === 0 ? (<><button className='btn-icon mr-2' title='Lock user' onClick={() => handleLocked(item.email_id, 1)}><i className="fas fa-unlock"></i></button> Unlocked</>) : (<><button className='btn-icon mr-2' title='Unlock user' onClick={() => handleLocked(item.email_id, 0)}><i className="fas fa-lock"></i></button> Locked</>)}

                  {item.actions = (<><button className='btn-icon mr-4' onClick={() => handleExPWModal(item.name, item.email_id)}><u>Expire password</u></button><button className='btn-icon' onClick={() => handleChPWModal(item.name, item.email_id)}><u>Reset password</u></button></>)}

                  {appData.push(item)}
                </>);
              });
              console.log(appData);
              setAppDataGridRender(appData);
            }
          } else if(response.data.status >= 400 && response.data.status <= 499){
            alert(response.data.message);
            //getToken();
          }
        })
        .catch(err=>console.log("error is",err));

        /* get_role_list */
        axios.post(serverConfig.api_base_url + "get_role_list",{token: token})
        .then(response=>{
          console.log('get_role_list response', JSON.parse(response.data.items));
          if(response.data.status === 200){
            setRolesList(JSON.parse(response.data.items));
          } else if(response.data.status >= 400 && response.data.status <= 499){
            alert(response.data.message);
            //getToken();
            //alert('Inactive session. Please try again.');
          }
        })
        .catch(err=>console.log("error is",err));
      }
    }
  }, [token, actions, userPrivileged]);

  const data = {
    columns: [
      { label: 'Name', field: 'name', sort: true, key: 'name' },
      { label: 'Email Id', field: 'email_id', sort: false, key: 'email_id' },
      { label: 'Active', field: 'active', sort: false, key: 'active' },
      { label: 'Locked', field: 'locked', sort: false, key: 'locked' },
      { label: 'Roles', field: 'roles', sort: false, key: 'roles' },
      { label: 'Organization', field: 'organization', sort: false, key: 'organization' },
      { label: 'Actions', field: 'actions', sort: false, key: 'roles' },
    ],
    rows: appDataGridRender,
  };

  const [rolesList, setRolesList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserEmail, setCurrentUserEmail] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [rolesInputs, setRolesInputs] = useState([]);
  const [curUserRole, setCurUserRole] = useState([]);

  const [rolesModalShow, setRolesModalShow] = useState(false);
  const handleCloseRolesModal = () => {
    setRolesInputs([]);//clear roles checkbox on close of modal
    setCurUserRole([]);
    setCurrentUser(null);
    setCurrentUserEmail(null);
    setCurrentUserRole(null);
    setClicked(false);
    setRolesModalShow(false);
  }

  const handleRolesModal = (username, useremail, roles) => {
    console.log(username, useremail, roles);
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setCurrentUserRole(roles);
    setRolesModalShow(true);
  }

  const [clicked, setClicked] = useState(false);
  const handleCheckboxChange = (event) => {
    const roleId = event.target.id;
    //console.log('clicked', clicked);
    if(!clicked){
      if(currentUserRole !== null && currentUserRole.length){
        setClicked(true);
        setRolesInputs(currentUserRole);
      }
    }
    if (event.target.checked) {
      setRolesInputs(values => ([...values, roleId ]));
    } else {
      if(clicked){
        setRolesInputs(rolesInputs.filter(e => e !== roleId));
      } else {
        setRolesInputs(currentUserRole && currentUserRole.filter(e => e !== roleId));
      }
    }
  }

  const handleApplyRole = () => {
    //console.log(roles);
    console.log('rolesInputs:', rolesInputs);
    console.log('CurrentUserRole:', currentUserRole);
    var grantList = [];
    var revokeList = [];
    // compare currentUserRole and rolesInputs
    for(var i = 0; i < rolesList.length; ++i) {
      let role = rolesList[i];
      if (rolesInputs.includes(role) && (currentUserRole === null || !currentUserRole.includes(role))) {
        grantList.push(role);
      } else if (currentUserRole != null && currentUserRole.includes(role) && !rolesInputs.includes(role)) {
        revokeList.push(role);
      }
    }
    console.log('grant list: ', grantList);
    console.log('revoke list:', revokeList);
    var changed = false;
    
    if(grantList.length){
      axios.post(serverConfig.api_base_url + "user_grant_role",{token: token, email_id: currentUserEmail, roles: grantList})
      .then(response=>{
        console.log('user_grant_role response', response);
        if(response.data.status === 200){
          console.log('success');
          if (!changed) {
            changed=true;
            setActions(!actions);// to refresh user list after role is updated
          }
          handleCloseRolesModal();
        } else if(response.data.status >= 400 && response.data.status <= 499){
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }

    if (revokeList.length) {
      axios.post(serverConfig.api_base_url + "user_revoke_role",{token: token, email_id: currentUserEmail, roles: revokeList})
      .then(response=>{
        console.log('user_revoke_role response', response);
        if(response.data.status === 200){
          console.log('success');
          if (!changed) {
            changed=true;
            setActions(!actions);// to refresh user list after role is updated
          }
          handleCloseRolesModal();
        } else if(response.data.status >= 400 && response.data.status <= 499){
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  const [exPWMsg, setExPWMsg] = useState("");
  const [showExPWModal, setShowExPWModal] = useState(false);
  const closeExPWModal = () => {
    setCurrentUser("");
    setCurrentUserEmail("");
    setExPWMsg("");
    setShowExPWModal(false);
  }
  const handleExPWModal = (username, useremail) => {
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setShowExPWModal(true);
  }
  const handleExpirePwd = (e) => {
    e.preventDefault();
    console.log({token: token, email_id: currentUserEmail});
    axios.post(serverConfig.api_base_url + "user_expire_password",{token: token, email_id: currentUserEmail})
    .then(response=>{
      console.log('user_expire_password response', response);
      if(response.data.status === 200){
        console.log('success');
        setExPWMsg("Expired password successfully");
        setTimeout(() => {
          closeExPWModal();
        }, 1000);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
        //getToken();
        //alert('Inactive session. Please try again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [chPWMsg, setChPWMsg] = useState("");
  const [resetPWMsg, setResetPWMsg] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showChPWModal, setShowChPWModal] = useState(false);
  const closeChPWModal = () => {
    setCurrentUser("");
    setCurrentUserEmail("");
    //setChPWMsg("");
    setResetPWMsg("");
    setNewPassword("");
    setShowChPWModal(false);
  }
  const handleChPWModal = (username, useremail) => {
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setShowChPWModal(true);
  }
  const handleChangePwd = (e) => {
    e.preventDefault();
    console.log({token: token, email_id: currentUserEmail, password: newPassword});
    axios.post(serverConfig.api_base_url + "user_change_password ",{token: token, email_id: currentUserEmail, password: newPassword})
    .then(response=>{
      console.log('user_change_password  response', response);
      if(response.data.status === 200){
        console.log('success');
        setChPWMsg("Password changed successfully");
        setTimeout(() => {
          closeChPWModal();
        }, 1000);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
        //getToken();
        //alert('Inactive session. Please try again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleResetPwd = (e) => {
    e.preventDefault();
    console.log({token: token, email_id: currentUserEmail});
    axios.post(serverConfig.api_base_url + "user_reset_password ",{token: token, email_id: currentUserEmail})
    .then(response=>{
      console.log('user_reset_password  response', response);
      if(response.data.status === 200){
        console.log('success');
        setResetPWMsg("A link to reset the password has been sent to " + currentUserEmail);
        setTimeout(() => {
          closeChPWModal();
        }, 2000);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
        //getToken();
        //alert('Inactive session. Please try again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleActive = (emailid, changeActive) => {
    console.log(emailid);
    console.log(changeActive);

    axios.post(serverConfig.api_base_url + "user_active",{token: token, email_id: emailid, active: changeActive})
    .then(response=>{
      console.log('user_active response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user active is set 0/1
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
        //getToken();
        //alert('Inactive session. Please try again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleLocked = (emailid, changeLock) => {
    console.log(emailid);
    console.log(changeLock);

    axios.post(serverConfig.api_base_url + "user_locked",{token: token, email_id: emailid, locked: changeLock})
    .then(response=>{
      console.log('user_locked response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user is locked / unlocked
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
        //getToken();
        //alert('Inactive session. Please try again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [createUserModalShow, setCreateUserModalShow] = useState(false);
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleCreateUser = (e) => {
    e.preventDefault();
    var formdata = {
      token:token, ...inputs
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "user_create",formdata)
    .then(response=>{
      console.log("User create response:", response);
      if(response.data.status === 200){
        setActions(!actions);// to refresh user list after user is created
        setInputs({});
        setCreateUserModalShow(false);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
        //getToken();
        //alert('Inactive session. Please click the button again.');
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <>

      <div className='page-heading'>
        <h1>Users</h1>
        {/* Create User */}
        <Button variant="transparent" className='btn-txt-black' onClick={() => setCreateUserModalShow(true)}>Create</Button>
        <Modal show={createUserModalShow} onHide={() => {setCreateUserModalShow(false); setInputs({});}} backdrop="static"keyboard={false} centered className='modal-small'>
          <Form onSubmit={handleCreateUser}>
            <Modal.Header closeButton>
              <Modal.Title>Create User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="First Name" name="first_name" value={inputs.first_name || ""} onChange={handleChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Last Name" name="last_name" value={inputs.last_name || ""} onChange={handleChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="email" placeholder="Email Id" name="email_id" value={inputs.email_id || ""} onChange={handleChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Password" name="password" value={inputs.password || ""} onChange={handleChange} required />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="transparent" onClick={() => {setCreateUserModalShow(false); setInputs({});}}>Cancel</Button>
              <Button variant="primary" type="submit" className='ml-3'><i className="fas fa-user-plus mr-2"></i>Create</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>

      {/* User list table */}
      <section>
        <div className='datatable datatable-users'>
          <MDBDataTable striped borderless data={data} entries={10} entriesOptions={[ 10, 20, 30 ]} searchLabel="Type here" />
        </div>
      </section>

      {/* Edit user role */}
      <Modal show={rolesModalShow} onHide={handleCloseRolesModal} backdrop="static"keyboard={false} centered className='modal-medium'>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Edit User Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Edit roles for {currentUser}</p>
            <Row>
              {rolesList.map((item, index) => (
                <Col key={index}><Form.Check type="checkbox" id={item} value={item} label={item} defaultChecked={currentUserRole && currentUserRole.includes(item)} onChange={handleCheckboxChange} /></Col>
              ))}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={handleCloseRolesModal}>Cancel</Button>
            <Button variant="primary" onClick={handleApplyRole} className='ml-3'>Apply</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Expire Password */}
      <Modal show={showExPWModal} onHide={closeExPWModal} backdrop="static"keyboard={false} centered className='modal-small'>
        <Form onSubmit={handleExpirePwd}>
          <Modal.Header closeButton>
            <Modal.Title>Expire Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Do you want to proceed with expire password for {currentUser}?</p>
            <p className="txt-green">{exPWMsg}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={closeExPWModal}>No</Button>
            <Button variant="primary" type="submit" className='ml-3'>Yes</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Change Password */}
      {/* <Modal show={showChPWModal} onHide={closeChPWModal} backdrop="static"keyboard={false} centered className='modal-small'>
        <Form onSubmit={handleChangePwd}>
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Change password for {currentUser}</p>
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="New password" name="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
            </Form.Group>
            <p className="txt-green">{chPWMsg}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={closeChPWModal}>Cancel</Button>
            <Button variant="primary" type="submit" className='ml-3'>Change</Button>
          </Modal.Footer>
        </Form>
      </Modal> */}

      {/* Reset Password */}
      <Modal show={showChPWModal} onHide={closeChPWModal} backdrop="static"keyboard={false} centered className='modal-small'>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {resetPWMsg ? <p className="txt-green" style={{textAlign: "left"}}>{resetPWMsg}</p> : <p>Do you want to proceed with reset password for {currentUser}?</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="transparent" onClick={closeChPWModal}>No</Button>
          <Button variant="primary" className='ml-3' onClick={handleResetPwd}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Users;
